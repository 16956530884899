import React from 'react';
import {
    makeMap, 
    makeMapOfPrimitives, 
    findRecursively, 
    gatherIdsRecursively, 
    gatherTree, 
    gatherAncestors, 
    treeFormatDataForList, 
    formatDataForList, 
    findMaxDepth, 
    flattenTreeFormattedData 
} from "./ListUtils";
import Utils from "./../general/Utils";
import TaimerComponent from "../TaimerComponent";
import PropTypes from "prop-types";
import Select from '@material-ui/core/Select';
import { SettingsContext } from '../SettingsContext';

import "./List.css";
import MenuItem from '@material-ui/core/MenuItem';

import ListHeader from "./ListHeader";
import PropsOnlyListRow from "./PropsOnlyListRow";
import PageSelector from "./PageSelector";
import debounce from 'lodash/debounce';
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual"; 

/* 
 * Last resort for if a client can't see your newly added column(s) on a list; 
 * change this string to something else – append a number to the end or something.
 */

const LIST_SETTINGS_KEY_PREFIX          = "taimer_list_settings_for_"; 
const LIST_VIRTUALIZATION_EXTRA_PADDING = 30; 

class BottomMarker extends PropsOnlyListRow {
    constructor(props) {
        super(props); 
    }


    defineClassName() {
        return "bottomMarker";
    }


    defineCells() {
        return {};
    }


    shouldComponentUpdate() {
        return false; 
    }
}

class List extends TaimerComponent {
    static defaultProps = {
        // Experimental feature props:
        virtualized: false,
        visible: undefined,
        simpleRowDefinition: false, // TODO: Not really used?

        // Others:
		className: "",
		columnDefaultWidth: 200,
        columns: [],
        columnOrder: undefined,
        // The 'columns' prop can either be an an array or an object with keys matching those of the 'listRowTypeMap' prop.
        // If multiple column configurations have been defined (implying it's defined as an object with arrays under each key of the object), the corresponding column config will be given to the matching ListRow.
        // E.g. 'columns.headerRow' config will be given to the ListRow whose type in 'listRowTypeMap' is 'headerRow'.
        columnHeaderConfigKey: undefined,
        // 'columnHeaderConfigKey', if defined (e.g. "headerRow"), will indicate which of the multiple column configurations in the 'columns' prop will be used to render the column headers at the top of the list.
        // If there are multiple column configurations in 'columns' and 'columnHeaderConfigKey' is undefined, the first one of the configurations in 'columns' will be used.
        controlOrder: false, 
        checkedRows: undefined,
        beforeVirtualizationOrderRotation: () => {},
        afterVirtualizationOrderRotation: () => {},
        data: [],
        defaultColumnHeaderConfig: {},
        disableInitialFocusOnRow: false,
        disableNewData: false, // TODO: Remove when state.newData isn't in use anymore.
        parentsExpandedOnInit: true,
		fluid: false,
        hideFluidFix: false,
		height: 600,
        minHeight: 224,
        enableToolbar: false,
		manualListWidth: false,
		trimHeight: 0,
		rowHeight: undefined,
        ignoreRowPropsChange: true, // Only relevant when props.noStateData = true.
        listRowType: undefined,
        listRowTypeMap: undefined,
        listRowTypeKey: "_type",
        listRowTypeClassNames: { default: "" },
        summaryRowType: undefined,
        manualCreate: false,
        rowDragging: false,
        newRow: {},
        newRowMap: undefined,
        newRowType: undefined,
        noStateData: false,
        showNoResultsMessage: false,
        noResultsMessage: undefined,
        minimizeRerendersInTrees: false,
		page: 1,
		pageCount: 999,
		totalCount: 999,
		perpage: 30,
		parentKey: "parentId",
        idType: "number",
        incrementalScroll: false,
        presentation: undefined,
        rowProps: {},
        rowCallbacks: {},
		summaryRowProps: {},
		rowKey: "id",
        rowOrder: undefined,
        reverseNewData: false,
        renderNewDataAtEnd: false,
		saveColumnConfig: false,
		sharedData: {},
		style: {},
		showPageSelector: false,
        controlPage: false,
		treeData: false,
		userListSettingsKey: undefined,
        useAllCheckedExcept: false,
        useGlobalAllChecked: true, // Rename this someday. Heh. 
		searchableHeader: false,
        hideHeaderButtons: false,
        hideHeader: false,
		emptyNewDataOnUpdate: true,
        allChecked: false,
        visibilityFilter: undefined,
        hideBottomMarker: true,

        // onDraggedRowDrop params: (the row that was dragged, the row over which the row was dropped).
        // if the second parameter is null, this indicates the row was dropped on a "non-allowed" row.
        onDraggedRowDrop: (dragger, droppedOver, currentOrder, dataMap, listRef) => {}, 
        dragDropIsAllowed: (dragged, draggedOver, currentOrder) => true,
        onDragStart: (e) => {},
        onDrag: (draggedRow, props, currentOrder) => {},

        onEdit: undefined,
        onAddNewRow: (newRows) => {},
		onSortRows: (columnName, isAscending) => {},
		onDataChange: () => {},
		onPageChange: () => {},
        onCheck: () => {},
		onCheckAll: () => {},
		onUncheckAll: () => {},
		onPerPageChange: () => {},
        onPressPlus: () => {},
        onToolbarExportClick: () => {},
        onToolbarEditClick: () => {},
        onToolbarDeleteClick: () => {},
        onMouseMove: () => {},
        onMouseLeave: () => {},
	};


	static propTypes = {
	    height: PropTypes.string
	};


	constructor(props, context) {
		super(props, context, "list/List");

		this.contentDiv       = React.createRef();
		this.outerMainWrapper = React.createRef();
		this.mainWrapper      = React.createRef();
		this.mainList         = React.createRef();
		this.summaryRow       = React.createRef();
		this.resizeMarker     = React.createRef();
		this.header           = React.createRef();
		this.pageSelector     = React.createRef();
        this.listFooter       = React.createRef();
        this.listHeader       = React.createRef();
		this.fluidFix         = React.createRef();

        this.initializationTimeout  = null;
        this.resizeFlipTimeout      = null;
        this.listElementReferences  = [];
        this.depthControlledColumns = [];
        this.maxDepth               = undefined;

        let conf = this.configureColumns(props.columns, true);

        // Attributes for rows -->
        const expandedParents = {};
		const flips           = {};

        if(Array.isArray(this.props.data)) {
            if(this.props.parentsExpandedOnInit)
                this.props.data.forEach(el => expandedParents[el[this.props.rowKey]] = true);

            this.props.data.forEach(el => flips[el[this.props.rowKey]] = 0);
        } else {
            console.warn("The data prop for List is not an array.");
        }
        // <-- attributes for rows.

        if(this.props.onColumnVisibilityChange) {
            this.props.onColumnVisibilityChange(conf.columns);
        }

        this.state = {
            mounted: false,
            newData: [], // TODO: Get rid of this separation for new and existing data; the id of the row should indicate.. Newness.
			data: this.props.data,
			dataPreformatted: false,
			height: this.props.height !== "fitRemaining" ? parseInt(this.props.height) : "100%",
			listWidth: conf.listWidth,
			columnWidths: conf.columnWidths,
			columns: conf.columns,
			currentPage: 1,
            checkedRows: this.props.checkedRows !== undefined ? makeMapOfPrimitives(this.props.checkedRows) : {},
            allCheckedExcept: undefined,
			allChecked: props.allChecked,
			minimumScaleWidth: conf.minimumScaleWidth,
            childrenVisibleRows: expandedParents,
            flips,
            dataRenderStartIndex: 0
		};
        
        // Note that initVirtualizationState does the same initialization for 
        // virtualization state as done here.
        // It's used to reset said state when data changes, etc.

        // Virtualization state:
        // From now on, the underscore before a property name indicates a state variable
        // that's used "outside" React's state, because it makes sense for this variables mutations to 
        // affect rendering.
       
        // Virtualization state -->
        this._previousStart     = 0;
        this._order             = {};
        this._prevDiff          = 0;
        this._visible           = (this.props.visible > this.props.data.length) ? this.props.data.length : this.props.visible;
        this._previousScrollTop = undefined;
        this._scrollEndTimeout  = undefined;


        // Hyvä.
        this.__rowConfigurations = {
            columnWidthMap: {},
            columnWidthMaps: {},
            columnOrder: [],
            columnConfig: {},
            columnConfigs: {},
            columnOrders: {},
            cellPresentations: {},
        };

        for(let i = 0; i < this._visible; ++i) {
            if(i >= this.props.data.length)
                break;

            this._order[i] = i; 
        }
        // <-- Virtualization state

        this.parentKey                 = props.parentKey;
        this.nextNewRowId              = -1;
        this.nextNewRowIdOld           = -1; // Used in the "old" way of adding a new row.
		this.formattedData             = [];
        this.currentOrder              = [];
        this.lastRenderRows            = [];
        this.dataInitialized           = false;
        this.virtualizationInitialized = false;
        this.pageChanged               = false;

        // ROW dragging state (not in React state, since the whole thing is very heavy and having 
        // List check prop and state equality on every drag event would be ineffective.
        this.dragging                           = false; 
        this.draggedRowId                       = undefined;
        this.currentlyDraggingOverId            = undefined; // Before TypeScript's enums, undefined will be used for no-value, and null for when the last dragging happened over a forbidden row type.
        this.bottomMarkerData                   = { id: "BOTTOM_MARKER" };

		// Method bindings.
        this.initVirtualizationState            = this.initVirtualizationState.bind(this);
        this.determineVisibleRowsAmount         = this.determineVisibleRowsAmount.bind(this);
        this.determineVirtualizationStartHeight = this.determineVirtualizationStartHeight.bind(this);
        this.onScroll                           = this.onScroll.bind(this);
        this.rotateVirtualizationOrder          = this.rotateVirtualizationOrder.bind(this);
        this.initScrollEndTimeout               = this.initScrollEndTimeout.bind(this);
        this.hasSavedConfig                     = this.hasSavedConfig.bind(this);
        this.getSavedConfig                     = this.getSavedConfig.bind(this);
        this.invalidateSavedConfig              = this.invalidateSavedConfig.bind(this);
        this.getSavedConfigKey                  = this.getSavedConfigKey.bind(this);
        this.configureColumns                   = this.configureColumns.bind(this);
		this.addNewRow                          = this.addNewRow.bind(this);
		this.newAddNewRow                       = this.newAddNewRow.bind(this);
		this.oldAddNewRow                       = this.oldAddNewRow.bind(this);
        this.removeNewRow                       = this.removeNewRow.bind(this);
        this.removeRow                          = this.removeRow.bind(this);
		this.emptyNewData                       = this.emptyNewData.bind(this);
		this.editData                           = this.editData.bind(this);
		this.runOnDataChange                    = this.runOnDataChange.bind(this);
        this.setData                            = this.setData.bind(this);
        this.getData                            = this.getData.bind(this);
        this.getItem                            = this.getItem.bind(this);
        this.getCheckedData                     = this.getCheckedData.bind(this);
		this.setPage                            = this.setPage.bind(this);
        this._setPage                           = this._setPage.bind(this);
        this.getVisibleRows                     = this.getVisibleRows.bind(this);
        this.toggleShowChildren                 = this.toggleShowChildren.bind(this);
        this.showChildren                       = this.showChildren.bind(this);
        this.gatherTree                         = this.gatherTree.bind(this);
        this.gatherFlips                        = this.gatherFlips.bind(this);
        this.flipRows                           = this.flipRows.bind(this);
        this.flipAllRows                        = this.flipAllRows.bind(this);
        this.startRowDrag                       = this.startRowDrag.bind(this);
        this.setCurrentlyDragging               = this.setCurrentlyDragging.bind(this);
        this.setCurrentlyDraggingOver           = this.setCurrentlyDraggingOver.bind(this);
        this.setCurrentlyDraggingOverNotAllowed = this.setCurrentlyDraggingOverNotAllowed.bind(this);
        this.isDragging                         = this.isDragging.bind(this);
        this.dragDropIsAllowed                  = this.dragDropIsAllowed.bind(this);
        this.endRowDrag                         = this.endRowDrag.bind(this);
        this.check                              = this.check.bind(this);
        this.checkAll                           = this.checkAll.bind(this);
        this.uncheckAll                         = this.uncheckAll.bind(this);
        this.getAllChecked                      = this.getAllChecked.bind(this);
        this.getAllCheckedExcept                = this.getAllCheckedExcept.bind(this);
		this.getCheckedRows                     = this.getCheckedRows.bind(this);
        this.toolbarShouldBeVisible             = this.toolbarShouldBeVisible.bind(this);
        this.noResultsMessageShouldBeShown      = this.noResultsMessageShouldBeShown.bind(this);
		this.saveColumnConfig                   = this.saveColumnConfig.bind(this);
        this.getColumnConfigKeys                = this.getColumnConfigKeys.bind(this);
		this.formatData                         = this.formatData.bind(this);
		this.calculateWidths                    = this.calculateWidths.bind(this);
		this._calculateWidths                   = this._calculateWidths.bind(this);
        this.configureRows                      = this.configureRows.bind(this);
		this.fixFluidWidth                      = this.fixFluidWidth.bind(this);
		this.addCloseColumnMenuWindowListener   = this.addCloseColumnMenuWindowListener.bind(this);
        this.resetCheckedRows                   = this.resetCheckedRows.bind(this);
        this.resetStateData                     = this.resetStateData.bind(this);
		this.windowOnResize                     = debounce(this.windowOnResize.bind(this), 100);
		this.startPageChangeAnimation           = this.startPageChangeAnimation.bind(this);
		this.endPageChangeAnimation             = this.endPageChangeAnimation.bind(this);
        this.runHeightSetCallback               = this.runHeightSetCallback.bind(this);
		this.startGrabScrollCounter             = this.startGrabScrollCounter.bind(this);
		this.stopGrabScrollCounter              = this.stopGrabScrollCounter.bind(this);
		this.startGrabScroll                    = this.startGrabScroll.bind(this);
		this.grabScroll                         = this.grabScroll.bind(this);
		this.endGrabScroll                      = this.endGrabScroll.bind(this);
        this.getScrollTop                       = this.getScrollTop.bind(this);
		this.bodyMouseMoveListenerForGrabScroll = event => this.grabScroll(event);
		this.bodyMouseUpListenerForGrabScroll   = event => this.endGrabScroll(event);
		this.onHeaderSearchChange               = this.onHeaderSearchChange.bind(this);
	}


    componentDidMount() {
        super.componentDidMount();

        const { minimumScaleWidth } = this.state;

        if(typeof(this.props.height) === "function") {
            this.runHeightSetCallback()
        }

        if(this.props.height === "fitRemaining") {
            this.determineAndSetHeightOnFit();
        }

        window.addEventListener("resize", this.windowOnResize);

        if(this.props.height === "auto") {
            this.determineAndSetHeightOnAuto();
        }

        if(this.props.fluid) {
            this.fixFluidWidth();
        }

        this.configureRows();

        // Store the handle of the timeout, so we can cancel it in componentWillUnmount.
        this.initializationTimeout = setTimeout(() => {
            let scaled = this.calculateWidths(this.state.columns, this.outerMainWrapper.current.clientWidth);

            this.setState({
                columnWidths: scaled.columnWidths, 
                listWidth: scaled.listWidth,
                mounted: true
            }, () => {
                if(Array.isArray(this.props.data) && this.props.data.length > 0) {
                    this.dataMap         = makeMap(this.state.data, this.props.rowKey);
                    this.formattedData   = this.formatData(this.props.visibilityFilter ? this.state.data.filter(this.props.visibilityFilter) : this.state.data);
                    this.dataInitialized = true;
                }

                this.runOnDataChange("initial");
            });
        }, 100);
    }


    determineVisibleRowsAmount() {
        const contentAreaHeight = this.mainList.current.clientHeight;
        const visible           = Math.ceil(contentAreaHeight / this.props.rowHeight) + 1;

        return (visible > this.props.data.length) ? this.props.data.length : visible;
    }


    determineVirtualizationStartHeight() {
        return this.state.dataRenderStartIndex * this.props.rowHeight;
    }


    createColumnOrder(order, columns) {
		let newOrder = [];

		for(let colName of order) {
			let found = columns.find(c => c.name === colName);

			// Fix the cause rather than this: it seems any element can be moved in the header. This should only be possible for .columns.
			if(found === undefined)
				continue;

			newOrder.push(found);
		}

		if(this.props.onColumnOrderChange) {
			this.props.onColumnOrderChange(newOrder.map(el => el.name));
		}

		return newOrder;
	}


    // TODO: When we move config storing into the db, refactor these methods.
    hasSavedConfig() {
        const config = localStorage.getItem(this.getSavedConfigKey());
        
        return Boolean(config);
    }


    // TODO: When we move config storing into the db, refactor these methods.
    getSavedConfig() {
        return cloneDeep(JSON.parse(localStorage.getItem(this.getSavedConfigKey())));
    }


    // TODO: When we move config storing into the db, refactor these methods.
    invalidateSavedConfig() {
        localStorage.removeItem(this.getSavedConfigKey());
    }


    getSavedConfigKey() {
        return `${LIST_SETTINGS_KEY_PREFIX}${this.props.userListSettingsKey}`;
    }


    // TODO: Should this be in ListHeader?
    configureColumns(columnsPropOrig, returnConf = false) {
        // Clone the columns prop because the forEach below mutates the object.
        let columnsProp        = cloneDeep(this.usesMultipleListRowTypes() ? columnsPropOrig[this.props.columnHeaderConfigKey] : columnsPropOrig); 
		let columnMap          = {};
		let columns            = [];
		let minimumScaleWidth  = 0;

        let hasSavedConfig     = false;
        let savedConfig        = false;
        let savedConfigIsValid = false;
        let savedConfigMap     = {};

        if(this.props.saveColumnConfig) {
            // TODO: Refactor everything pertaining to local storage.
            const currentColumnNames = columnsProp.map(c => c.name);

            hasSavedConfig     = this.hasSavedConfig();
            savedConfig        = hasSavedConfig ? this.getSavedConfig() : false;
            savedConfigIsValid = hasSavedConfig && currentColumnNames.length === savedConfig.length && Utils.arrayIntersect([currentColumnNames, savedConfig.map(c => c.name)]).length === currentColumnNames.length;
            savedConfigMap     = (this.props.saveColumnConfig && hasSavedConfig && savedConfig) ? makeMap(savedConfig, "name") : false;

            savedConfigIsValid = savedConfigIsValid && (Object.keys(savedConfigMap).map(key => parseInt(savedConfigMap[key].width)).filter(width => width === null || isNaN(width)).length === 0);

            if(!savedConfigIsValid) {
                this.invalidateSavedConfig();
            }
        }

        const keys               = Object.keys(columnsProp);
        let relevantColumnsConf  = columnsProp;
        // let relevantColumnsConf  = !this.usesMultipleListRowTypes() ? columnsProp : columnsProp[this.props.columnHeaderConfigKey || keys[0]] || [];

        /* 
         * If List is using config saving, but the currently saved 
         * configuration conflicts with the current columns 
         * (e.g. there are a different amount of columns, 
         * or not all saved columns can be found in the current columns, or vice versa.),
         * invalidate the currently saved configuration by removing it.
         */
        if(this.props.saveColumnConfig && !savedConfigIsValid) {
            this.invalidateSavedConfig();
        }

        relevantColumnsConf.forEach(column => {
            Object.keys(this.props.defaultColumnHeaderConfig).forEach(key => {
                if(column.hasOwnProperty(key)) {
                    return;
                }

                column[key] = this.props.defaultColumnHeaderConfig[key];
            });
        });

        // Default values for column header attributes.
        // Warning: edited by reference.
		relevantColumnsConf.forEach(column => {
			column['menuOpen']                 = false;
			column['columnVisibilityMenuOpen'] = false;
			column['showMenu']                 = column.hasOwnProperty("showMenu") ? column.showMenu : true;
			column['showMenuContainer']	       = column.hasOwnProperty("showMenuContainer") ? column.showMenuContainer : false;
            column['visible']                  = column.hasOwnProperty("visible") ? column.visible : true;
            column['visibleInToolbar']         = column.hasOwnProperty("visibleInToolbar") ? column.visibleInToolbar : false;
			column['resizeable']               = column.hasOwnProperty("resizeable") ? column.resizeable : true;
			column['moveable']                 = column.hasOwnProperty("moveable") ? column.moveable : true;
			column['hideable']                 = column.hasOwnProperty("hideable") ? column.hideable : true;
			column['sortable']                 = column.hasOwnProperty("sortable") ? column.sortable : true;
			column['showResizeMarker'] 		   = column.hasOwnProperty("showResizeMarker") ? column.showResizeMarker : true;
            column['type'] 					   = column.hasOwnProperty("type") ? column.type : undefined;
			column['width'] 				   = column.hasOwnProperty("width") ? column.width : this.props.columnDefaultWidth;
            column['alignment']                = column.hasOwnProperty("alignment") ? column.alignment : "left";

            if(this.props.saveColumnConfig && hasSavedConfig && savedConfigIsValid) {
                column = { ...column, ...savedConfigMap[column['name']] };
            }

			columnMap[column.name] = column;
			minimumScaleWidth += column['width'];
        });

        // If there's a config saved, we need to 
        relevantColumnsConf = this.props.saveColumnConfig && hasSavedConfig && savedConfigIsValid ? savedConfig.map(column => columnMap[column.name]) : relevantColumnsConf;

		// localStorage schema for columns:
		// columns' order in the array defines the order in the ui.
		/*
			[
				{
					name: id,
					width: 50,
					visible: true
				},
				{
					name: name,
					width: 200,
					visible: true
				},
				{
					name: parentId,
					width: 150,
					visible: false
				},
			]
		*/

        for(let col of relevantColumnsConf)
            columns.push(columnMap[col.name]);

		const widths 	 = this.calculateWidths(columns, this.outerMainWrapper.current && this.outerMainWrapper.current.clientWidth);
        this.columnOrder = columns.map(c => c.name);

        let conf = {
			listWidth: widths.listWidth,
			columnWidths: widths.columnWidths,
			columns: this.createColumnOrder(this.columnOrder, columns),
			minimumScaleWidth
        };

        if(returnConf)
            return conf;
        else
            this.setState(conf);
	}


	fixFluidWidth() {
		// Fix width of header and content to be equal so they match
		if(!this.mainList.current || !this.fluidFix.current)
			return;

		let scrollbarExtra = Math.max(0, this.mainList.current.offsetWidth - this.mainList.current.clientWidth);

		if(scrollbarExtra > 0)
			scrollbarExtra++;

		this.fluidFix.current.style.width = `${scrollbarExtra}px`;
	}


	componentWillUnmount() {
		super.componentWillUnmount();

        clearTimeout(this.initializationTimeout);

		window.removeEventListener("resize", this.windowOnResize);
	}


    // Getting a little complex here..
    // But almost anything is better than reconciling such a component as List is.
    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.dataPreformatted === true && nextState.dataPreformatted === false)
            return false;

        return !this.props.noStateData || (!isEqual(nextProps, this.props) || !isEqual(nextState, this.state));
    }

	windowOnResize() {
        this.resizeFlipTimeout && clearTimeout(this.resizeFlipTimeout);

		this.resizeFlipTimeout = setTimeout(() => {
			if(this.props.height === "fitRemaining") {
				this.determineAndSetHeightOnFit();
            }
            
            let scaled = this.calculateWidths(this.state.columns, this.outerMainWrapper.current.clientWidth);
            
            this.setState({ 
                columnWidths: scaled.columnWidths, 
                listWidth: scaled.listWidth 
            }, () => {
                this.flipAllRows();

                if(typeof(this.props.height) === "function") {
                    this.runHeightSetCallback();
                }
            });
		}, 200);
    }


    // Ensures you get the column configs in the correct order; main config first, and only after that the others.
    getColumnConfigKeys() {
        return (this.usesMultipleListRowTypes() ? [this.props.columnHeaderConfigKey, ...Object.keys(this.props.columns).filter(f => f !== this.props.columnHeaderConfigKey)] : ["default"]);
    }


    // TODO: Refactor; this is the first draft of this function.
    configureRows() {
        const usesMultipleListRowTypes = this.usesMultipleListRowTypes();
        const columnOrder              = this.state.columns.filter(c => c.visible).map(c => c.name);
        const columnOrderClone         = clone(columnOrder);

        this.__rowConfigurations = {
            columnWidthMap:    {},
            columnWidthMaps:   {},
            columnOrder:       columnOrder,
            columnConfig:      {},
            columnConfigs:     {},
            columnOrders:      {},
            cellPresentations: {},
        };

		for(let cw of this.state.columnWidths) {
            this.__rowConfigurations.columnWidthMap[cw['name']] = cw['width'];
        }

		for(let col of cloneDeep(this.state.columns)) {
			let key = this.getColumnKey(col);

			this.__rowConfigurations.columnConfig[key]       = col;
			this.__rowConfigurations.columnConfig[key].width = this.__rowConfigurations.columnWidthMap[key];
        }

        (this.getColumnConfigKeys()).forEach(rowType => {
            let orderDefined = false;

            this.__rowConfigurations.columnConfigs[rowType]     = {};
            this.__rowConfigurations.columnWidthMaps[rowType]   = {};
            this.__rowConfigurations.cellPresentations[rowType] = {};
            this.__rowConfigurations.columnOrders[rowType]      = [];

            // All other column orders are defined based on the column header order.
            if(rowType === this.props.columnHeaderConfigKey) {
                this.__rowConfigurations.columnOrders[rowType] = columnOrderClone;

                orderDefined = true;
            }

            let presentation = {};
            let colConf      = usesMultipleListRowTypes && rowType !== this.props.columnHeaderConfigKey ? this.props.columns[rowType] : this.state.columns;
            // colConf          = typeof(colConf) === "function" ? colConf(this.__rowConfigurations.columnConfigs[this.props.columnHeaderConfigKey]) : colConf;
            colConf          = typeof(colConf) === "function" ? colConf(this.__rowConfigurations.columnOrder, this.__rowConfigurations.columnWidthMap) : colConf;

            if(typeof(this.props.presentation) === "object" && this.props.presentation.hasOwnProperty(rowType) && this.props.presentation[rowType] !== undefined) {
                presentation = this.props.presentation[rowType];
            }

            for(let col of colConf) {
                let key = col.hasOwnProperty("name") ? col.name : col.field;

                this.__rowConfigurations.cellPresentations[rowType][key] = (!col.hasOwnProperty("presentation") && !presentation.hasOwnProperty(key)) ? false : col.presentation || presentation[key];

                this.__rowConfigurations.columnConfigs[rowType][key] = col;

                // The default row type can't use a function to determine its widths,
                // since the default row's widths are what's used to determine other rows' widths.
                if(rowType !== this.props.columnHeaderConfigKey && typeof(col.width) === "function") {
                    this.__rowConfigurations.columnWidthMaps[rowType][key] = col.width(this.__rowConfigurations.columnWidthMaps[this.props.columnHeaderConfigKey], columnOrderClone); 
                } else if(col.width !== undefined) {
                    this.__rowConfigurations.columnWidthMaps[rowType][key] = col.width;               
                } else {
                    this.__rowConfigurations.columnWidthMaps[rowType][key] = this.__rowConfigurations.columnWidthMap[key];
                }                

                if(!orderDefined) {
                    this.__rowConfigurations.columnOrders[rowType].push(key);
                }
            }
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if(!isEqual(this.state.data, prevState.data)) {
            this.dataMap       = makeMap(this.state.data, this.props.rowKey);
            // this.formattedData = this.formatData(this.props.visibilityFilter ? this.state.data.filter(this.props.visibilityFilter) : this.state.data);
        }

        // TODO: Move to componentDidMount.
        if(this.props.virtualized && (!this.virtualizationInitialized || !isEqual(prevProps.data, this.props.data))) {
            this.initVirtualizationState();

            this.virtualizationInitialized = true;

            this.rotateVirtualizationOrder();
        }

        if(!isEqual(prevState.columns, this.state.columns) || !isEqual(prevState.columnWidths, this.state.columnWidths)) {
            this.configureRows();

            this.flipAllRows();

            return;
        }

		if(this.props.height === "auto") {
			this.determineAndSetHeightOnAuto();
        }

        // TODO: Is this needed anymore?
	if(this.props.fluid) {
            this.fixFluidWidth();
        }

        if(this.props.emptyNewDataOnUpdate && prevProps.data !== this.props.data) {
            this.emptyNewData();
        }

        if(this.props.checkedRows !== undefined && !isEqual(this.props.checkedRows, prevProps.checkedRows)) {
            this.setState({ checkedRows: makeMapOfPrimitives(this.props.checkedRows) });
        }

        // TODO: Is this sufficient?
        if(!this.props.noStateData && !isEqual(prevProps.data, this.props.data)) {
            this.setState({ data: this.props.data }, () => {
                if(this.pageChanged) {
                    this.mainList.current.scrollTop = 0; 

                    this.pageChanged = false;
                }

                this.runOnDataChange("propDataChange");
            });
        }

        // TODO: Move into functions. For example the rowOrder flip thingy must be done on its own or in lieu of data having changed.
        if(this.props.noStateData) {
            const flipAllForRowProps = !this.props.ignoreRowPropsChange && !isEqual(prevProps.rowProps, this.props.rowProps);

            const dataChangedMap = {
                props: !isEqual(prevProps.data, this.props.data),
                state: !isEqual(prevState.data, this.state.data) 
            };

            if(dataChangedMap.props || dataChangedMap.state) {
                if(this.pageChanged) {
                    this.mainList.current.scrollTop = 0; 

                    this.pageChanged = false;
                }

                const dataKey      = dataChangedMap.props ? "props" : "state";
                const previousData = dataChangedMap.props ? prevProps : prevState;

                // Flips -->
                // Maps and id sets for comparing elements that were in the prev set and are in the cur set;
                // We need to compare their data in case it has been changed by some other actors, and then flip the elements.
                const prevIds     = []; 
                const newIds      = [];
                const prevDataMap = {};
                const newDataMap  = {};
                const prevNewEls  = [];

                for(let pEl of previousData.data) {
                    prevDataMap[pEl[this.props.rowKey]] = pEl;
                    prevIds.push(this.props.idType === "number" ? Number(pEl[this.props.rowKey]) : String(pEl[this.props.rowKey]));
                }

                for(let nEl of this[dataKey].data) {
                    newDataMap[nEl[this.props.rowKey]] = nEl;
                    newIds.push(this.props.idType === "number" ? Number(nEl[this.props.rowKey]) : String(nEl[this.props.rowKey]));
                }

                for(let psEl of prevState.data) {
                    if(psEl[this.props.rowKey] > 0 || this.props.emptyNewDataOnUpdate || (!newDataMap[psEl[this.props.rowKey]] && dataKey === "state")) {
                        continue;
                    }

                    newIds.push(psEl[this.props.rowKey]);
                    prevNewEls.push(psEl);
                }

                const intersection  = Utils.arrayIntersect([prevIds, newIds]);
                const treesGathered = {};
                let flips           = clone(this.state.flips);
                const oldFlips      = clone(this.state.flips);
                const tempFlips     = {};
                const finalFlips    = {};
                const currentIds    = {};
                const prevData      = treeFormatDataForList(previousData.data, this.props.parentKey, undefined, this.props.idType);
                const newData       = treeFormatDataForList(this[dataKey].data, this.props.parentKey, undefined, this.props.idType);

                // Add all new ids to the flip map.
                for(let x in this[dataKey].data) {
                    let y = this[dataKey].data[x];

                    // Mark those ids that are still in use, since we need a diff between 
                    // relevant rows and removed rows.
                    currentIds[y[this.props.rowKey]] = true;

                    // If the id already exists, no need to do anything here.
                    if(flips.hasOwnProperty(y[this.props.rowKey]))
                        continue;

                    // There's a new row in the set, and we need to check whether its parents are
                    // in the previous set and should be flipped.

                    flips[y[this.props.rowKey]] = 0;

                    for(let oid of this.gatherTree(y[this.props.rowKey], this[dataKey].data, newData))
                        treesGathered[oid] = true;
                }

                // Next, extract those rows that are, for what ever reason, not in our data anymore.
                for(let f in flips) {
                    if(currentIds[f])
                        continue;

                    // For all rows that were in the previous set, but are not in this set;
                    // gather their parents and all relevant rows in the tree
                    // and flip them, so that the parent and all relevant rows will rerender,
                    // visualizing that this removed row is not in the set anymore.

                    for(let nid of this.gatherTree(f, previousData.data, prevData))
                        treesGathered[nid] = true;
                }

                // Flips all existing rows.
                // TODO: What the hell?
                for(let id in treesGathered) {
                    if(currentIds[id]) {
                        tempFlips[id] = flips[id] ^ 1;
                    }
                }

                // Overwrite existing flips with our new flips, keeping the old flip value where changes are not relevant.
                flips = { ...flips, ...tempFlips };

                // If those elements that are still in the set, but whose data has changed, have not been flipped yet, flip them.
                let toGatherTreesFor = []; 

                for(let intersectionId of intersection) {
                    // Element is equal or has already been flipped.
                    if(isEqual(prevDataMap[intersectionId], newDataMap[intersectionId]) || flips[intersectionId] !== oldFlips[intersectionId]) {
                        continue;
                    }

                    toGatherTreesFor.push(intersectionId);

                    // ??
                    // if(!isEqual(prevDataMap[id], newDataMap[id]) && flips[id] === oldFlips[id])
                    // flips[id] = flips[id] ^ 1;
                }

                for(let toGatherId of toGatherTreesFor) {
                    let tree = this.gatherTree(toGatherId, this[dataKey].data, newData);

                    for(let flipId of tree) {
                        if(flips[flipId] !== oldFlips[flipId])
                            continue;

                        flips[flipId] = flips[flipId] ^ 1;
                    }
                }

                // Filter flips down to only those that are in the current set of elements.
                for(let curId of newIds) {
                    finalFlips[curId] = flips[curId];
                }
                // <-- flips end.

                // Child visibility -->
                let childrenVisibleRows = this.state.childrenVisibleRows;

                if(this.props.parentsExpandedOnInit) {
                    for(let i in this[dataKey].data) {
                        let el = this[dataKey].data[i];

                        if(childrenVisibleRows.hasOwnProperty(el[this.props.rowKey]))
                            continue;

                        childrenVisibleRows[el[this.props.rowKey]] = true;
                    }
                }
                // <--

                const state = {
                    data: this[dataKey].data.concat(prevNewEls.filter(d => dataKey === "props" ? !this.props.emptyNewDataOnUpdate : !currentIds[d[this.props.rowKey]] )), 
                    flips: finalFlips, 
                    childrenVisibleRows 
                };

                if(this.props.checkedRows === undefined) {
                    state.checkedRows = { ...this.state.checkedRows, ...(this.props.useGlobalAllChecked && (this.state.allChecked || (this.props.useAllCheckedExcept && this.state.allCheckedExcept !== undefined)) ? makeMapOfPrimitives(this[dataKey].data.map(e => e[this.props.rowKey]).filter(id => this.state.allCheckedExcept === undefined || !this.state.allCheckedExcept[id])) : {}) };
                }

                this.setState(state, () => {
                    // Extend this to "state data mode" (!this.props.noStateData) too.
                    this.dataMap = makeMap(this.state.data, this.props.rowKey);

                    const origin = this.dataInitialized ? "propDataChange" : "initial";

                    if(!flipAllForRowProps) {
                        this.runOnDataChange(origin);
                    } else {
                        this.flipAllRows(() => this.runOnDataChange(origin));
                    }

                    if(!this.dataInitialized) {
                        this.dataInitialized = true;
                    }
                });

            } else if(flipAllForRowProps || prevProps.treeData !== this.props.treeData) {
                this.flipAllRows();
            } 
        }

        if(!isEqual(prevState.data, this.state.data)) {
            this.runOnDataChange("stateDataChange");
        }

        if(!isEqual(this.props.columns, prevProps.columns)) {
            // What the hell? No idea why this works, in products list the columns weren't updating correctly so had to do something.
            // A console.log here also made it work.
            setTimeout(() => { 
                this.configureColumns(this.props.columns);
            }, 0);
        }
	}


    resetCheckedRows() {
        this.uncheckAll();

        this.setState({
            allCheckedExcept: undefined,
			allChecked: this.props.allChecked
        }); 
    }


    // Used to reset List's state data by making it a deep copy of props.data again, 
    // and flipping all rows after that.
    resetStateData() {
        this.setState({ data: cloneDeep(this.props.data) }, this.flipAllRows); 
    }


	startPageChangeAnimation() {
		this.contentDiv.current.style.opacity = "0.0";
	}


	endPageChangeAnimation() {
		this.contentDiv.current.style.opacity = "1";
	}


    runHeightSetCallback() {
        const elements = {
            contentDiv: this.contentDiv.current,
            outerMainWrapper: this.outerMainWrapper.current,
            mainWrapper: this.mainWrapper.current,
            mainList: this.mainList.current,
            summaryRow: this.summaryRow.current,
            resizeMarker: this.resizeMarker.current,
            header: this.header.current,
            pageSelector: this.pageSelector.current,
            listFooter: this.listFooter.current,
            listHeader: this.listHeader.current,
            fluidFix: this.fluidFix.current
        };

        this.props.height(elements, (height) => {
            this.mainWrapper.current.style.height = Math.max((height - 56), this.props.minHeight) + "px"; 
        }, this);
    }


	determineAndSetHeightOnFit() {
        const height = window.innerHeight - this.outerMainWrapper.current.getBoundingClientRect().y - this.listFooter.current.offsetHeight + this.props.trimHeight;
        this.mainWrapper.current.style.height = Math.max((height - 56), this.props.minHeight) + "px";
	}


    determineAndSetHeightOnAuto() {
        // Äläläläläläl
		const rowHeight = this.props.rowHeight !== undefined ? this.props.rowHeight : (this.props.listRowType.hasOwnProperty("rowDimensions") ? parseInt(this.props.listRowType.rowDimensions.height) : "44px");
		let totalHeight = this.state.data.length * rowHeight + this.state.newData.length * rowHeight;

		if(this.props.summaryRowType !== undefined) {
			const SummaryRowType = this.props.summaryRowType;
			let summaryRowHeight = 0;

			if(this.props.summaryRowHeight)
				summaryRowHeight = parseInt(this.props.summaryRowHeight);
			else if(SummaryRowType.hasOwnProperty("rowHeight"))
				summaryRowHeight = parseInt(SummaryRowType.rowHeight);
			else
				summaryRowHeight = 44; // most common height

			totalHeight += summaryRowHeight;
        }

		this.mainWrapper.current.style.height = totalHeight + "px";
    }


    usesMultipleListRowTypes() {
        return !Array.isArray(this.props.columns) && typeof this.props.columns === "object";
    }


	// Only possible with the middle mouse button.
	startGrabScrollCounter(event) {
		if(event.button !== 1 && !event.ctrlKey)
			return;

		const coordinates = { x: event.clientX, y: event.clientY };

		this.startGrabScrollTimeout = setTimeout(() => {
			this.startGrabScroll(coordinates);
		}, 0);
	}


	stopGrabScrollCounter(event) {
		clearTimeout(this.startGrabScrollTimeout);

		this.startGrabScrollTimeout = undefined;
	}


	startGrabScroll(coordinates) {
		this.scrollingByGrab 	 		   = true;
		this.scrollingStartedAtCoordinates = coordinates;
		this.scrollPositionsWhenStarted    = { x: this.mainWrapper.current.scrollLeft, y: this.mainList.current.scrollTop };

		document.body.addEventListener("mousemove", this.bodyMouseMoveListenerForGrabScroll);
		document.body.addEventListener("mouseup", this.bodyMouseUpListenerForGrabScroll);
		window.addEventListener("mouseup", this.bodyMouseUpListenerForGrabScroll);

        	// this.mainWrapper.current.classList.add("scrolling");
	}


	grabScroll(event) {
		if(!this.scrollingByGrab || this.scrollingStartedAtCoordinates === undefined)
			return;

		const newX = event.clientX;
		const newY = event.clientY;

		this.mainWrapper.current.scrollLeft = this.scrollPositionsWhenStarted.x + (this.scrollingStartedAtCoordinates.x - newX);
		this.mainList.current.scrollTop 	= this.scrollPositionsWhenStarted.y + (this.scrollingStartedAtCoordinates.y - newY);
	}


	endGrabScroll() {
		this.scrollingByGrab 			   = false;
		this.scrollingStartedAtCoordinates = undefined;
		this.scrollPositionsWhenStarted    = undefined;

		document.body.removeEventListener("mousemove", this.bodyMouseMoveListenerForGrabScroll);
		document.body.removeEventListener("mouseup", this.bodyMouseUpListenerForGrabScroll);
		window.removeEventListener("mouseup", this.bodyMouseUpListenerForGrabScroll);
	}


    getScrollTop() {
        return this.mainList.current.scrollTop;
    }


    startRowDrag(props) {
        if(!this.props.rowDragging)
            return; 

        this.dragging   = true;
        this.draggedRow = props;
    }


    setCurrentlyDragging(props) {
        this.draggedRow = props;
    }


    setCurrentlyDraggingOver(props) {
        this.currentlyDraggingOverRow = props;
    }


    setCurrentlyDraggingOverNotAllowed() {
        this.currentlyDraggingOverProps = null;
    }


    isDragging() {
        return this.dragging;
    }


    // rowData and props are those of the row which the drop would happen _on_.
    dragDropIsAllowed(props) {
        if(!this.dragging)
            return false; 

        this.props.onDrag(this.draggedRow, props, this.currentOrder);

        return this.props.dragDropIsAllowed(this.draggedRow, props, this.currentOrder);
    }
    
    
    endRowDrag() {
        const dragged = this.draggedRow;
        const over    = this.currentlyDraggingOverRow;

        // Nothing to do if the row was dropped on itself.
        if(dragged && over && dragged !== over) {
            const [data, callback = () => {}] = this.props.onDraggedRowDrop(
                this.draggedRow,
                this.currentlyDraggingOverRow, 
                cloneDeep(this.currentOrder), 
                this.dataMap, 
                this
            );

            this.setState({ data }, callback);
        }

        this.draggedRow               = undefined;
        this.currentlyDraggingOverRow = undefined;
        this.dragging                 = false;
    }


    addNewRow(newData = {}) {
        return (this.props.noStateData || this.props.disableNewData) ? this.newAddNewRow(newData) : this.oldAddNewRow(newData);
    }

    
    // Optionally accepts either an object or an array. If an array is passed, it must be an array of objects.
    newAddNewRow(newData = {}) {
        newData = !Array.isArray(newData) ? [newData] : newData;

        const allNewIds           = [];
        const childrenVisibleRows = clone(this.state.childrenVisibleRows);
        const newRows             = newData.map(row => {
            let newRow;

            // If multiple ListRow subtypes are in use and multiple newRows have also been declared, and said definition exists for this type.
            if(this.usesMultipleListRowTypes() && this.props.newRowMap !== undefined && row.hasOwnProperty(this.props.listRowTypeKey) && this.props.newRowMap.hasOwnProperty(row[this.props.listRowTypeKey])) {
                newRow = clone(this.props.newRowMap[row[this.props.listRowTypeKey]]);
            } else {
                newRow = clone(this.props.newRow);
            }

            // newRow.id  = String(this.nextNewRowId--);
            newRow[this.props.rowKey] = String(this.nextNewRowId--);

            // In render, if this key (__originalCreationKey) exists in a row's data, 
            // it will be used as the key of the component instead of data[this.props.rowKey].
            // This is to prevent a row, whose identifier has just changed,
            // from being constructed again because it's key changes.
            newRow['__originalCreationKey'] = newRow[this.props.rowKey]; 

            allNewIds.push(newRow[this.props.rowKey]);

            for(let k in row) {
                newRow[k] = row[k];
            }
            
            if(newRow[this.props.parentKey]) {
                childrenVisibleRows[newRow[this.props.parentKey]] = true;
            }

            return newRow;
        });

        let newStateData = clone(this.state.data);
        let flips        = clone(this.state.flips);

        if(!this.props.reverseNewData) {
            newStateData = newRows.concat(newStateData);
        } else {
            newStateData = newStateData.concat(newRows);
        }

        this.setState({ 
            data: newStateData, 
            flips: this.gatherFlips(allNewIds, newStateData), 
            childrenVisibleRows: childrenVisibleRows, 
            hideOverlay: true 
        }, () => {
            this.props.onAddNewRow(newRows);
            this.runOnDataChange("addNewRow");
        });

        return allNewIds;
    }


    oldAddNewRow(additionalData = {}) {
		let newData = this.state.newData;
		let newRow  = { children: [], data: JSON.parse(JSON.stringify(this.props.newRow)) };

        newRow.data['id'] = String(this.nextNewRowIdOld--);

		if(additionalData)
			newRow.data = Object.assign(newRow.data, additionalData);

		if(!this.props.reverseNewData)
			newData.unshift(newRow);
		else
			newData.push(newRow);

        this.setState({ newData: newData }, () => {
            this.runOnDataChange(); 
        });
    }


    // If you use props.noStateData and PropsOnlyListRow, add children to existing rows by
    // calling addNewRow({ parentId: parent's id }).
	addNewChildRow(parentId, newChildRow = undefined) {
		if(parseInt(parentId) < 0)
            throw new Error(`Can't add a child under a parent that has a negative (temporary) id: ${parentId}; save the parent first.`);

		let parent = findRecursively(this.formattedData, row => row.data[this.props.rowKey] == parentId);

		if(!parent || !parent.hasOwnProperty("children"))
			return false;

		let newRow = Object.assign({}, this.props.newRow);

		if(newChildRow)
			newRow = Object.assign({}, newChildRow);
		
        newRow[this.props.parentKey] = parentId;
        newRow[this.props.rowKey] = String(this.nextNewRowIdOld--);

		parent.children.push({
			data: newRow,
			children: []
		});

		this.setState({ dataPreformatted: true });
	}

    
    // This should only be used for removing new rows when !this.props.noStateData.
    removeNewRow(id) {
	    this.setState({ newData: this.state.newData.filter(r => r.data[this.props.rowKey] !== id) }, () => this.runOnDataChange("removeNewRow"));
	}


    removeRow(ids) {
        ids         = Array.isArray(ids) ? ids : [ids];
        const idMap = makeMapOfPrimitives(ids);

        let data  = this.state.data.filter(d => !idMap[d[this.props.rowKey]]);
        let flips = this.gatherFlips(ids);

        ids.forEach(id => delete flips[id]);
       
        this.setState({ data, flips }, () => {
            this.runOnDataChange("removeRow"); 
        });
    }


    // TODO: Removing rows in batches.
    // TODO: Add a default origin parameter for all functions that call runOnDataChange, so the caller can customize
    // the origin to be able to distinguish data change "events" from each other.

    emptyNewData(callback = () => {}) {
        if(!this.props.noStateData) {
            this.setState({ newData: [] }, () => {
                if(typeof(callback) === "function")
                    callback();

                this.runOnDataChange("emptyNewData");
            });
        } else {
            const idsToRemove = [];
            const data        = this.state.data.filter(d => {
                const isNew = Number(d[this.props.rowKey]) < 0;

                if(isNew) {
                    idsToRemove.push(d[this.props.rowKey]);
                }

                return !isNew;
            });

            this.setState({ data: data, flips: this.gatherFlips(idsToRemove) }, () => {
                if(typeof(callback) === "function")
                    callback();

                this.runOnDataChange("emptyNewData");
            });
        }
	}


    editData(data, identifier = undefined) {
        const key    = this.props.rowKey !== undefined ? this.props.rowKey : "id";
        const allMap = makeMapOfPrimitives(this.state.data.map(d => d[key]), false);

        let map;

        if(!Array.isArray(identifier)) {
            identifier = this.props.idType === "number" 
                ? Number(identifier === undefined ? data[key] : identifier) 
                : String(identifier === undefined ? data[key] : identifier);

            map = {
                ...allMap,
                [identifier]: true
            };
        } else {
            map = {
                ...allMap,
                ...makeMapOfPrimitives(identifier, true)
            };
        }

		this.setState({ 
            data: this.state.data.map(r => (!map[r[key]] ? r : { ...r, ...data })), 
            flips: this.gatherFlips(identifier) 
        }, () => this.runOnDataChange("editData"));
	}


    setData(data, callback = () => {}) {
        this.setState({ data }, callback);
    }


    // NOTE: hasn't been tested (or therefore even developed) for ListRows that have internal state.
    // TODO: When attributes are implemented on the list (separating them from data), refactor this function. 
    getData() {
        const checkedRows = makeMapOfPrimitives(this.getCheckedRows());
        const data        = cloneDeep(this.state.data);

        return data.map(row => {
            row['_checked'] = checkedRows[row[this.props.rowKey]] || false;

            return row;
        });
    }


    // TODO: When attributes are implemented on the list (separating them from data), refactor this function. 
    getItem(id) {
        id = Number(id);

        return (this.dataMap && this.dataMap.hasOwnProperty(id)) ? this.dataMap[id] : this.state.data.find(d => Number(d[this.props.rowKey]) === id);
    }


    getCheckedData() {
        return this.getData().filter(d => d['_checked'] === true);
    }


    runOnDataChange(origin = undefined) {
        this.props.onDataChange({ data: cloneDeep(this.state.data), newData: cloneDeep(this.state.newData), origin });
    }


    getVisibleRows() {
        return this.listElementReferences.filter(r => r.current !== null).map(r => r.current);
    }


    gatherTree(id, data = undefined, dataTreeFormatted = false) {
        if(!this.props.treeData)
            return [id];

        data = data !== undefined ? data : this.state.data;

        return !this.props.minimizeRerendersInTrees 
            ? 
            gatherTree(id, data, dataTreeFormatted, this.props.parentKey, this.props.idType) 
            : 
            gatherAncestors(id, data, this.props.parentKey, this.props.idType);
    }


    // Climb back to the trunk from a leaf, id is a leaf's id.
    // We need to inform the trunk that its leaves need to update, meaning the trunk itself needs to update.
    gatherFlips(ids, data = undefined, dtf = false) {
        ids       = !Array.isArray(ids) ? [ids] : ids;
        const map = {};

        for(let id of ids) {
            for(let treeId of this.gatherTree(id, data, dtf)) {
                map[treeId] = true;
            }
        }

        const treeIds = Object.keys(map);
        let idsToUse  = (!treeIds || treeIds.length === 0) ? ids : treeIds;
        const flips   = clone(this.state.flips);

        for(let x in idsToUse) {
            flips[idsToUse[x]] = flips[idsToUse[x]] ^ 1;
        }

        return flips;
    }


    flipRows(ids, callback = () => {}) {
        this.setState({ flips: this.gatherFlips(ids) }, callback);
    }


    flipAllRows(callback = () => {}) {
        const flips = clone(this.state.flips);

        for(let id in flips)
            flips[id] = flips[id] ^ 1;

        this.setState({ flips }, () => {
            if(typeof(callback) !== "function")
                return;

            callback();
        });
    }


    // TODO:
    // Refactor both of these into a general "toggleAttribute" method that toggles an attribute
    // on a row specified by its id.
    toggleShowChildren(id, show = undefined) {
        let childrenVisibleRows = this.state.childrenVisibleRows;

        if(!childrenVisibleRows[id] || show)
            childrenVisibleRows[id] = true;
        else
            childrenVisibleRows[id] = false;

        this.setState({ childrenVisibleRows, flips: this.gatherFlips(id) });
    }

    
    showChildren(id) {
        this.toggleShowChildren(id, true);
    }


    check(ids, state = undefined, callback = () => {}, all = false) {
        ids                    = !Array.isArray(ids) ? [ids] : ids;
        const checkedRows      = this.state.checkedRows;
        const allCheckedExcept = this.state.allCheckedExcept;

        for(let id of ids) {
            if((state === undefined && checkedRows[id]) || state === false) {
                delete checkedRows[id];

                if(allCheckedExcept !== undefined)
                    allCheckedExcept[id] = true;
            } else {
                checkedRows[id] = true;            

                if(allCheckedExcept !== undefined)
                    delete allCheckedExcept[id];
            }
        }

        this.setState({
            flips: this.gatherFlips(ids),
            checkedRows,
            allChecked: all && state,
            allCheckedExcept
        }, () => {
            callback();

            this.props.onCheck({ checkedRows: this.state.checkedRows, allChecked: this.state.allChecked, allCheckedExcept: this.state.allCheckedExcept });
        });
    }


    checkAll(checked = undefined) {
		const newAllChecked    = checked !== undefined ? checked : !this.state.allChecked;
        const allCheckedExcept = this.props.useAllCheckedExcept && newAllChecked ? {} : undefined;

        if(!this.props.noStateData) {
            for(let ref of this.listElementReferences)
                ref.current && ref.current.checkAll(newAllChecked);

            newAllChecked ? this.props.onCheckAll() : this.props.onUncheckAll();

            this.setState({ allChecked: newAllChecked, allCheckedExcept });
        } else {
            // const allIds = this.state.data.map(el => el.id);
            const allIds = !newAllChecked ? Object.keys(this.state.checkedRows) : this.state.data.map(el => el[this.props.rowKey]);

            newAllChecked ? this.props.onCheckAll(allIds) : this.props.onUncheckAll(allIds);

            this.setState({ allCheckedExcept }, () => this.check(allIds, newAllChecked, () => {}, true));
        }
    }


    uncheckAll() {
        this.checkAll(false); 
    }


    // If you're trying to determine whether the user has pressed the header's checkbox in "all checked except" mode, use getAllCheckedExcept.
    getAllChecked() {
        return this.props.useGlobalAllChecked
            ? this.state.allChecked
            : this.state.data.map(d => d[this.props.rowKey]).every(id => this.state.checkedRows[id]);
    }


    getAllCheckedExcept() {
        return this.state.allCheckedExcept === undefined ? false : this.state.allCheckedExcept; 
    }


    // If the ListRow doesn't use any state data (this.props.noStateData == true),
    // this method only returns the ids of the checked rows.
    getCheckedRows() {
        let rows = [];

        if(!this.props.noStateData)
            for(let ref of this.listElementReferences)
                rows.push(ref.current.getData());
        else
            rows = Object.keys(this.state.checkedRows);

		return rows;
    }


    toolbarShouldBeVisible() {
        return this.props.enableToolbar && (Object.keys(this.state.checkedRows).length > 0 || this.state.allChecked);
    }


    noResultsMessageShouldBeShown() {
        // The original overlay and the no results overlay can't be shown at the same time.
        return !this.props.showOverlay 
            && 
            (typeof(this.props.showNoResultsMessage) === "function" 
            ? this.props.showNoResultsMessage() 
            : this.props.showNoResultsMessage && (!this.state.data || this.state.data.length === 0));
    }


	setPage(page) {
		if(this.pageSelector === undefined || this.pageSelector.current === null)
			return;

		this.pageSelector.current.setPage(page);
	}


    _setPage(page) {
        this.pageChanged = true;

        if(!this.props.controlPage)
            this.setState({ currentPage: page });

        this.props.onPageChange(page);
    }


    saveColumnConfig(columns) {
        if(!this.props.saveColumnConfig) {
            return;
        } else if(!this.props.userListSettingsKey) {
            throw "List is set to save its column configuration, but props.userListSettingsKey is falsy.";
        }

        const fieldsToSave = ["name", "width", "visible"];

		localStorage.setItem(this.getSavedConfigKey(), JSON.stringify(columns.map(c => {
			const nc = {};

			for(let field in c) {
				if(fieldsToSave.indexOf(field) > -1) {
					nc[field] = c[field];
                }
            }

			return nc;
		})));
    }

    
    getMinimumWidthForColumn = column => {
        const savedConfig = this.getSavedConfig();
        let width = 0;
        if (savedConfig) {
            const savedCol = savedConfig.find(x => x.name == column.name);
            width = savedCol ? savedCol.width : this.getMinimumWidthFromProps(column);
        } else {
            width = this.getMinimumWidthFromProps(column);
        }
        return width;
    }


    getMinimumWidthFromProps = column => {
        let item = {};
        if (!Array.isArray(this.props.columns)) {
            const colArr = this.usesMultipleListRowTypes() && typeof(this.props.columns) === "object" ? this.props.columns[this.props.columnHeaderConfigKey] : this.props.columns;
            const found = colArr.find(x => this.getColumnKey(x) == this.getColumnKey(column));

            if (found) item = found;
        } else {
            item = (this.props.columns).find(x => this.getColumnKey(x) == this.getColumnKey(column)) || {};
        }
        return item.width || 0;
    }


    formatData(data) {
        return !this.props.treeData 
            ? 
    	    formatDataForList(data, this.props.rowKey) 
            : 
    	    treeFormatDataForList(data, this.props.parentKey, undefined, this.props.idType);
    }


    calculateWidths(columns, elementWidth = 0, afterColumnResize = false) {
        let conf = this._calculateWidths(columns, elementWidth, afterColumnResize);

        if(this.props.onWidthCalculate) {
            this.props.onWidthCalculate(conf);
        }

        return conf;
    }


    _calculateWidths(columns, elementWidth = 0, afterColumnResize = false) {
		let scaledTotal    = 0;
		let columnWidths   = [];
		let scaledColumns  = [];
        let nonResizeables = columns.filter(c => !c.resizeable);
        let staticSize     = nonResizeables.length === 0 ? 0 : nonResizeables.map(c => c.width).reduce((acc, cur) => acc + cur, 0);
        let totalWidth     = columns.filter(c => c.visible).map(c => c.width).reduce((acc, cur) => acc + cur, 0);

		columns.forEach(c => {
			columnWidths.push({
				name: c.name,
                width: c.width,
                percentual: String((c.width / totalWidth * 100)) + "%"
			});
		});

        // Commented out & removed this onlyUp from every call of this function. 
        // Replaced with afterColumnResize to let user resize the column to whatever size they want (minimum width not taken into account).
		// if(onlyUp)
        // 	elementWidth = Math.max(totalWidth, elementWidth);

		if(elementWidth > 0) {
			columns.forEach(c => {
                let minimumWidth = this.getMinimumWidthForColumn(c);
                if (afterColumnResize) {
                    const old = this.state.columnWidths.find(x => x.name == c.name) || {width: 0};
                    if (old.width != c.width) {
                        minimumWidth = c.width;
                    }
                }
				let scaledWidth = c.width;
				if(c.visible && c.resizeable)
					scaledWidth = Math.max(minimumWidth, Math.round(((elementWidth - staticSize) / (totalWidth - staticSize)) * c.width));

				scaledTotal += c.visible ? scaledWidth : 0;
				scaledColumns.push({
					name: c.name,
					width: scaledWidth
				});
			});
        };

		return { listWidth: scaledTotal > 0 ? scaledTotal : totalWidth, columnWidths: scaledColumns.length > 0 ? scaledColumns : columnWidths };
    }


    initVirtualizationState() {
        this._previousStart = 0;
        this._order         = {};
        this._prevDiff      = 0;
        this._visible       = this.determineVisibleRowsAmount();

        for(let i = 0; i < this._visible; ++i) {
            if(i >= this.props.data.length)
                break;

            this._order[i] = i; 
        }
    }


    // onScroll and rotateVirtualizationOrder are separated so that showing a slice
    // can be done "manually" without a scrolling event, like in the case of data changing.
    onScroll(e) {
        if(!this.props.virtualized)
            return;

        if(this.props.incrementalScroll && this._previousScrollTop !== undefined) {
            if(e.target.scrollTop > this._previousScrollTop) {
                e.target.scrollTop = (Math.floor(e.target.scrollTop / this.props.rowHeight + 1)) * this.props.rowHeight;
            } else if(e.target.scrollTop < this._previousScrollTop) {
                e.target.scrollTop = Math.floor(e.target.scrollTop / this.props.rowHeight) * this.props.rowHeight;            
            }
        }
        
        this._previousScrollTop = e.target.scrollTop;

        // Clear the timeout, since we're gonna set it again 
        // in rotateVirtualizationOrder.
        clearTimeout(this._scrollEndTimeout);

        this.props.beforeVirtualizationOrderRotation();

        // afterVirtualizationOrderRotation is called as the 
        // callback of the setState call in rotateVirtualizationOrder.
        this.rotateVirtualizationOrder(e.target.scrollTop);
    }


    // When determining the order, we don't yet know whether there will be enough
    // elements to show, so this._order might be mutated again in render if there aren't enough
    // renderRows.
    rotateVirtualizationOrder(top = undefined) {
        top = top === undefined ? this.mainList.current.scrollTop : top;

        this._visible = this.determineVisibleRowsAmount();

        const start   = top >= this.props.rowHeight ? Math.round(top / this.props.rowHeight) : 0;
        const diff    = ((start - this._previousStart) * -1) % this._visible;

        for(let o in this._order) {
            this._order[o] += diff;
        }

        let cur;

        for(let o in this._order) {
            cur = this._order[o];

            if(cur < 0) {
                this._order[o] = this._visible + cur;
            } else if(cur >= this._visible) {
                this._order[o] = cur - this._visible;
            }
        }

        this._prevDiff      = diff;
        this._previousStart = start;

        this.setState({
            dataRenderStartIndex: start
        }, this.initScrollEndTimeout);
    }


    initScrollEndTimeout() {
        this._scrollEndTimeout = setTimeout(this.props.afterVirtualizationOrderRotation, 100);
    }


	addCloseColumnMenuWindowListener() {
		window.addEventListener("click", this.closeColumnMenuWindowListener);
	}


	onHeaderSearchChange(filter, columnName) {
		this.props.onHeaderSearchChange(filter, columnName);
    }
    
    
    getColumnKey = col => {
        return col.hasOwnProperty("name") ? col.name : col.field;
    }


    // TODO: Rework row ordering.
    render() {
        const usesMultipleListRowTypes = this.usesMultipleListRowTypes();
		const { tr }                   = this;

        // STYLES -->
        let listStyle     = { height: typeof(this.props.height) == "function" ? "100%" : this.state.height }; // Hmm..
        let contentsStyle = {};

        if(this.props.virtualized) {
            let height = this.props.data.length * this.props.rowHeight;

            contentsStyle.height = this.mainWrapper.current !== null && height < parseInt(this.mainWrapper.current.style.height) ? this.mainWrapper.current.style.height : height;
        }

        // let contentsStyle     = { height: this.props.virtualized ? this.props.data.length * this.props.rowHeight : undefined };
        let mainListWrapperStyle = {};

        if(this.props.fluid) {
            listStyle.minWidth = contentsStyle.minWidth = this.props.minWidth;
		} else {
            listStyle.width = contentsStyle.width = this.state.listWidth + "px";
        }

        // Scrollbar compensation.
        if(this.props.virtualized && !this.props.fluid) {
            listStyle.width = String((parseInt(listStyle.width) + LIST_VIRTUALIZATION_EXTRA_PADDING) + "px");
        }

        if(this.props.hideHeader) {
        	mainListWrapperStyle.paddingTop = 0;
        }

		if(this.props.manualListWidth) {
			listStyle.width = parseInt(this.props.manualListWidth) + "px";
        }
        // <-- STYLES

        // Setting state here causes a re-render, but it's wanted after a treeData list has used preformatted data.
        // Get rid of this and change what ever treeDataList is to use PropsOnlyListRow.
        // if(this.state.dataPreformatted)
            // this.setState({ dataPreformatted: false });

        // Needs to be in componentDidUpdate, 
        // but not where it was previously.
        // Slows things down a lot in virtualized mode.
        this.formattedData = this.formatData(this.props.visibilityFilter ? this.state.data.filter(this.props.visibilityFilter) : this.state.data);
        this.formattedData = this.formattedData !== undefined && Array.isArray(this.formattedData) ? this.formattedData : [];

        this.currentOrder = (this.props.treeData ? flattenTreeFormattedData(this.formattedData) : this.formattedData).map(d => d.data[this.props.rowKey]);

        let virtualizationData = [];
        let startHeight        = 0;

        // TODO: Potential problem with the slice.
        if(this.props.virtualized) {
            virtualizationData = this.formattedData.slice(this.state.dataRenderStartIndex, this.state.dataRenderStartIndex + this._visible);
            startHeight        = this.determineVirtualizationStartHeight();
        }

		const columnAmount     = this.state.columns.length;
		const rootClassName    = this.props.rootClassName || "";
		const addedClassName   = this.props.className;
        // const columnOrder      = this.state.columns.filter(c => c.visible).map(c => c.name);
        // const columnOrderClone = clone(columnOrder);
        const fluidClassName   = this.props.fluid ? "inFluidMode" : "";

        // Row-ordering -->
        let renderRows = [];
        let newRows    = !this.props.noStateData ? this.state.newData.map(row => {
    		row.newRow = true;
			return row;
        }) : [];
		let oldRows    = (this.props.virtualized ? virtualizationData : this.formattedData).map(row => {
			row.newRow = false;
			return row;
        });

        let rows = this.props.noStateData ? ([...oldRows]) : ([...(!this.props.renderNewDataAtEnd ? newRows : oldRows), ...(!this.props.renderNewDataAtEnd ? oldRows : newRows)]);

        // NOTE: When props.rowOrder prop !== undefined, it's passed to treeFormatForDataList, which will use the order to form tree-structured data for List to use. 
        // BUT this only works for noStateData for now; don't want to break older implementations.

        // TODO: Do something to this. 
        // Now some of the props are only relevant in noStateData mode.
		if(this.props.rowOrder !== undefined && !this.props.noStateData) {
			let map = {};
			let renderRowMap = {};

			for(let r in rows)
				map[rows[r].data[this.props.rowKey]] = rows[r];

			for(let i in this.props.rowOrder) {
				let row = map[this.props.rowOrder[i]];

				renderRowMap[row.data[this.props.rowKey]] = row;

				renderRows.push(row);
			}

			rows.filter(row => row.newRow).forEach(row => (!renderRowMap[row.data[this.props.rowKey]]) && (renderRows.unshift(row)));
        } else if(this.props.noStateData && !this.props.controlOrder) {
            let newRows      = [];
            let existingRows = [];

            for(let r in rows) {
                let row = rows[r];

                if(row.data[this.props.rowKey] < 0)
                    newRows.push(row);
                else
                    existingRows.push(row); 
            }

            if(this.props.renderNewDataAtEnd)
                renderRows = [...existingRows, ...newRows];
            else
                renderRows = [...newRows.reverse(), ...existingRows];
        } else {
            renderRows = rows;
        }

        if(renderRows.length > this.listElementReferences.length) {
            for(let i in renderRows) {
                this.listElementReferences[i] = React.createRef();
            }
        }

        // First thought on scrolling to the bottom and "running out" of data.
        // Saved for later. This has been solved in a hacky way below.
        // if(this.props.virtualized && renderRows.length < this.props.visible) {
            // const max   = renderRows.length - 1;
            // this._order = {};

            // Object.values(this._order)
                // .filter(orderNr => orderNr <= max)
                // .forEach((orderNr, o) => this._order[o] = orderNr);
        // }

        // <-- Manual row-ordering.
		this.lastRenderRows = renderRows;
        // <-- Row-ordering.

        const { columnWidthMap, columnWidthMaps, columnOrder, columnConfig, columnConfigs, columnOrders, cellPresentations } = this.__rowConfigurations;
        const listRowType          = this.props.listRowType;
        const listRowTypeMap       = this.props.listRowTypeMap;
        const listRowTypeKey       = this.props.listRowTypeKey;
        const flips                = clone(this.state.flips);
		const OverlayComponent     = this.props.overlayComponent;
        const renderSummaryRow     = this.props.summaryRowType !== undefined; // TODO: Refactor all implementations to use row types and remove this concept.
        const SummaryRowType       = this.props.summaryRowType; // TODO: Needed anywhere?
        const columnMap            = Utils.makeMap(this.state.columns, "name");

        // The checking behavior is getting too complex and needs refactoring.
        // When props.useGlobalAllChecked === true, checking the checkbox in the List's header means ALL rows, including those not in props/state data at this moment will be checked automatically.
        // When props.useGlobalAllChecked === false, checking the header's checkbox only checks those rows in the current data set, but check states will still persist from previous data sets.
        // In the latter mode checking all rows in the current view manually activates the header checkbox, but this is not reflected in List's state's allChecked being true. 
        const checkedRows         = clone(this.state.checkedRows);
        const allChecked          = this.props.useGlobalAllChecked ? (this.state.allChecked || (this.props.useAllCheckedExcept && this.state.allCheckedExcept !== undefined)) : this.state.data.map(d => d[this.props.rowKey]).every(id => this.state.checkedRows[id]);
        const childrenVisibleRows = clone(this.state.childrenVisibleRows);

        // Stateful conditions that are inferred from other state data.
        const toolbarVisible       = this.toolbarShouldBeVisible();

		return (
			<div className={`mainListOuterWrapper ${addedClassName} ${rootClassName}`} ref={this.outerMainWrapper} style={this.props.style} id={this.props.id !== false ? this.props.id : false}>
                {!this.props.hideHeader && this.props.virtualized && <div className="paddingHider" style={{ width: LIST_VIRTUALIZATION_EXTRA_PADDING }}></div>}
                <div className={`mainListWrapper ${addedClassName} ${fluidClassName}`} ref={this.mainWrapper} style={mainListWrapperStyle}>
                {
                    <ListHeader
                        ref={this.listHeader}
                        visible={!this.props.hideHeader}
                        hideHeaderButtons={this.props.hideHeaderButtons}
                        allChecked={this.state.allChecked || (this.props.useAllCheckedExcept && this.state.allCheckedExcept !== undefined)}
                        mainWrapper={this.mainWrapper}
                        additionalToolbarButtons={this.props.additionalToolbarButtons}
                        additionalToolbarColumns={this.props.additionalToolbarColumns}
                        hiddenToolbarColumns={this.props.hiddenToolbarColumns}
                        outerMainWrapper={this.outerMainWrapper}
                        resizeMarker={this.resizeMarker}
                        fluidFix={this.fluidFix}
                        hideFluidFix={this.props.hideFluidFix}
                        className={addedClassName}
                        columnAmount={this.state.columns.length}
                        columnWidths={this.state.columnWidths}
                        columns={this.state.columns}
                        toolbarMode={toolbarVisible}
                        fluid={this.props.fluid}
                        minWidth={this.props.minWidth}
                        listWidth={this.state.listWidth}
                        listRef={this}
                        onSortRows={this.props.onSortRows}
                        onColumnConfigChange={(columns, whatChanged) => {
                            const newState   = {};
                            this.columnOrder = columns.map(c => c.name); // this.columnOrder + the order is also significant in state.columns..?

                            newState.columns = this.createColumnOrder(this.columnOrder, columns);

                            if(whatChanged.visibility) {
                                if(typeof(this.props.onColumnVisibilityChange) === "function") {
                                    this.props.onColumnVisibilityChange(columns);
                                }
                            
                                let widths = this.calculateWidths(columns, this.state.listWidth);

                                newState.columnWidths = widths.columnWidths;
                                newState.listWidth    = widths.listWidth;
                            }

                            if(whatChanged.width) {
                                let widths = this.calculateWidths(columns, this.outerMainWrapper.current.clientWidth, true);

                                newState.columnWidths = widths.columnWidths;
                                newState.listWidth    = widths.listWidth;
                            }

                            this.setState(newState, () => {
                                this.flipAllRows();
                                this.saveColumnConfig(columns);
                            });
                        }}
                        onSetColumnOrder={order => {
                            this.columnOrder = order;

                            this.setState({
                                columns: this.createColumnOrder(order, this.state.columns) 
                            }, () => this.flipAllRows()); 
                        }}
                        checkedRowsAmount={!this.state.allCheckedExcept ? Object.keys(this.state.checkedRows).length : this.props.totalCount - Object.keys(this.state.allCheckedExcept).length}
                        maxColumnMenuHeight={this.contentDiv.current !== null ? this.contentDiv.current.offsetHeight - 80 : 350}
                    />
                }
					<div className={`mainList ${addedClassName} ${fluidClassName}`} style={listStyle} ref={this.mainList} onScroll={this.onScroll} onMouseDown={this.startGrabScrollCounter} onMouseUp={e => { this.stopGrabScrollCounter(e); this.endGrabScroll(); }}>
						<div ref={this.resizeMarker} className={`resizeMarker ${addedClassName}`}></div>
                        <div ref={this.contentDiv} className={`contents ${addedClassName}`} style={contentsStyle} onMouseMove={(event) => this.props.onMouseMove(event, this.mainList.current.scrollTop, this)} onMouseLeave={() => this.props.onMouseLeave(this)}>
                            {this.state.mounted && renderRows.length > 0 && (!this.props.virtualized ? renderRows : Object.keys(this._order)).map((i, elIndex) => {
                                const row = !this.props.virtualized ? i : renderRows[this._order[i]];

                                // TODO: Refactor virtualization data slicing so this isn't needed.
                                // If we've scrolled "past the end of the data", row will be undefined.
                                if(row === undefined) {
                                    return null;
                                }

                                const ListElementComponent = listRowTypeMap !== undefined && row.data.hasOwnProperty(listRowTypeKey) && listRowTypeMap.hasOwnProperty(row.data[listRowTypeKey]) ? listRowTypeMap[row.data[listRowTypeKey]] : listRowType;
                                const colConfig            = row.data[listRowTypeKey] !== this.props.columnHeaderConfigKey && typeof this.props.columns === "object" && columnConfigs.hasOwnProperty(row.data[listRowTypeKey]) ? columnConfigs[row.data[listRowTypeKey]] : columnConfig;
                                const colWidths            = row.data[listRowTypeKey] !== this.props.columnHeaderConfigKey && typeof this.props.columns === "object" && columnWidthMaps.hasOwnProperty(row.data[listRowTypeKey]) ? columnWidthMaps[row.data[listRowTypeKey]] : columnWidthMap;
                                const colOrder             = row.data[listRowTypeKey] !== this.props.columnHeaderConfigKey && typeof this.props.columns === "object" && columnOrders.hasOwnProperty(row.data[listRowTypeKey]) ? columnOrders[row.data[listRowTypeKey]] : columnOrder;

                                row.newRow                 = row.data[this.props.rowKey] < 0;
                                row.checked                = checkedRows[row.data[this.props.rowKey]] || false;
                                row.allChecked             = allChecked;
                                row.childrenVisible        = childrenVisibleRows[row.data[this.props.rowKey]] || false;

                                let props = {
                                    ref: this.listElementReferences[elIndex],
                                };

                                if(this.props.virtualized) {
                                    props.key = i;
                                } else if(row.data.hasOwnProperty("__originalCreationKey")) {
                                    props.key = row.data['__originalCreationKey'];
                                } else {
                                    props.key = !row.newRow ? row.data[this.props.rowKey !== undefined ? this.props.rowKey : "id"] || false : row.data.id || false;
                                }

                                if(!ListElementComponent) {
                                    return null;
                                }

                                return <ListElementComponent
                                	{...props}
                                    {...Object.assign({}, row,
                                        {
                                            listRef: this,
                                            mainColumnConfig: columnConfig,
                                            className: usesMultipleListRowTypes ? this.props.listRowTypeClassNames[row.data[listRowTypeKey]] || "" : "",
	                                    	columnConfig: colConfig,
		                                    columnOrder: colOrder,
                                            columnOrders: columnOrders,
		                                    columnWidths: this.state.columnWidths,
                                            columnWidthMap: colWidths,
                                            columnWidthMaps: columnWidthMaps,
                                            columnMap: columnMap,
                                            columnConfigs: columnConfigs,
                                            cellPresentations: cellPresentations,
                                            disableInitialFocus: this.props.disableInitialFocusOnRow,
                                            flex: Boolean(this.props.fluid),
                                            fluid: Boolean(this.props.fluid),
                                            contentDivRef: this.contentDiv.current,
                                            rowConfiguration: this.props.rowConfig,
		                                    rowProps: this.props.rowProps,
                                            rowCallbacks: this.props.rowCallbacks,
                                            top: this.props.virtualized ? this._order[i] * this.props.rowHeight + startHeight : 0,
                                            rowHeight: this.props.rowHeight,
                                            order: this.props.virtualized ? this._order[i]: undefined,
		                                    recursionLevel: 0,
                                            hidden: false,
                                            onEdit: this.props.onEdit,
                                            rowDraggingEnabled: this.props.rowDragging,
                                            onDragStart: this.props.onDragStart,
                                            idType: this.props.idType,
                                            ignoreRowPropsChange: this.props.ignoreRowPropsChange,
                                            rowKey: this.props.rowKey,
                                            listRowTypeMap: listRowTypeMap,
                                            listRowTypeKey: listRowTypeKey,
                                            rowType: row.data.hasOwnProperty(listRowTypeKey) ? row.data[listRowTypeKey] : "default",
                                            manualCreate: this.props.manualCreate,
                                            multipleListRowTypes: usesMultipleListRowTypes,
                                            noStateData: this.props.noStateData,
                                            usesState: !this.props.noStateData,
                                            noColorVariance: this.props.noColorVariance,
                                            sharedData: this.props.sharedData,
                                            simpleRowDefinition: this.props.simpleRowDefinition,
                                            checkedRows: checkedRows,
                                            childrenVisibleRows: childrenVisibleRows,
                                            flips: flips,
                                            flip: flips[row.data[this.props.rowKey]],
                                            virtualized: this.props.virtualized,
                                            rowIndex: elIndex
		                                })
                                    } />;
                            })}
                            {!this.props.hideBottomMarker && <BottomMarker 
                                listRef={this}
                                rowDraggingEnabled={true} 
                                noColorVariance={true}
                                data={this.bottomMarkerData}
                            />}
						</div>
						{
							renderSummaryRow
							&& 
							<SummaryRowType
								ref={this.summaryRow}
								key="summary-row"
								rows={renderRows}
								{...{ listRef: this, columnConfig: columnConfig, rowProps: this.props.summaryRowProps, tree: this.formattedData, columnOrder: columnOrder, columnWidths: this.state.columnWidths, columnWidthMap: columnWidthMap, sharedData: this.props.sharedData }} />
						}
					</div>
					{(this.props.showOverlay && !this.state.hideOverlay && (this.state.newData.length == 0)) && (<OverlayComponent {...this.props.overlayProps} />)}
                    {(this.noResultsMessageShouldBeShown()) && <div className="no-results-overlay" style={{ top: !this.props.hideHeader ? 48 : 0, width: listStyle.width }}>
                        <div className="message">{this.props.noResultsMessage === undefined ? this.tr("No search results") : this.props.noResultsMessage}</div>
                    </div>}
				</div>
				<div className={this.props.showPageSelector ? "listFooter" : "listFooter zeroHeight"} ref={this.listFooter}>
					{this.props.showPageSelector && <div className="footerHalf left">
						<PageSelector ref={this.pageSelector} page={this.props.page} onPageChange={this._setPage} pageCount={this.props.pageCount} controlPage={this.props.controlPage} />
					</div>}
					{this.props.showPageSelector && <div className="footerHalf right">
						<Select
							className="perPageSelector"
							variant="outlined"
							color="primary"
							value={this.props.perpage}
							onChange={(e, val) => this.props.onPerPageChange(e.target.value)}>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={30}>30</MenuItem>
							<MenuItem value={50}>50</MenuItem>
							<MenuItem value={75}>75</MenuItem>
							<MenuItem value={100}>100</MenuItem>
                        </Select>
                        <p style={{ display: "inline-block" }}>
                            {this.tr('Displaying')} {this.props.perpage * ((this.props.controlPage ? this.props.page : this.state.currentPage) - 1) + (this.state.data.length > 0 ? 1 : 0)} – {
                                this.props.perpage * (this.props.controlPage ? this.props.page : this.state.currentPage) < this.props.totalCount ? this.props.perpage * (this.props.controlPage ? this.props.page : this.state.currentPage) : this.props.totalCount
                            } {this.tr('of')} {this.props.totalCount} {this.tr('records')}
                        </p>
					</div>}
				</div>
			</div>
		);
	}
}

export default List;
