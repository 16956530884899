import React from 'react';
import PropTypes from "prop-types";
import $ from 'jquery';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

/* local components */
import List from './../list/List';
import DataHandler from './../general/DataHandler';
import PropsOnlyListRow from './../list/PropsOnlyListRow';
import ListCell from './../list/ListCell';

// import './../../general/TabAttachments.css';

class AddonConfirmationRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {});
    }

    defineCells() {
        const { columnOrder, columnWidthMap, data, sharedData: {companies} } = this.props;

        const getChangedCellsStyle= (field) => {
            if (data.new_addon)
                return 'red';
            return data.changed.includes(field) ? 'red' : 'inherit';
        }

        const commonProps = {
            editable: false,
        }

        const cells = {
            "new_addon": (
                <ListCell
                    value={data.new_addon ? "*" : ''}
                    style={{color: getChangedCellsStyle('new_addon')}}
                    {...commonProps} />
            ),            
            "activated": (
                <ListCell
                    value={data.activated.toString()}
                    style={{color: getChangedCellsStyle('activated')}}
                    {...commonProps} />
            ),
            "setting_name": (
                <ListCell
                    value={data.setting_name}
                    style={{color: getChangedCellsStyle('setting_name')}}
                    {...commonProps} />
            ),
            "addon_limit": (
                <ListCell
                    value={data.addon_limit}
                    style={{color: getChangedCellsStyle('addon_limit')}}
                    {...commonProps} />
            ),
            "company": (
                <ListCell
                    value={data.companies_id > 0 ? companies.find(c => c.id == data.companies_id).name : 'all companies'}
                    style={{color: getChangedCellsStyle('company')}}
                    {...commonProps} />
            ),                        
            "expires": (
                <ListCell
                    value={data.expires == "0000-00-00 00:00:00" ? 'no expiring' : data.expires}
                    style={{color: getChangedCellsStyle('expires')}}
                    {...commonProps} />
            ),            
        };

        return cells;
    }

}

class AddonConfirmationDialog extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {

        const { data, customer, onDialogClose, onDialogSave, companies } = this.props;
        
        const columns = [
            { name: "new_addon", header: "New addon", width: 120 },
            { name: "setting_name", header: "Name", width: 350 },
            { name: "activated", header: "Active", width: 100 },
            { name: "addon_limit", header: "Limit", width: 100 },
            { name: "company", header: "Companies", width: 250 },
            { name: "expires", header: "Expires at", width: 140 },
        ];

        return (
            <Dialog open maxWidth="lg">
                <DialogTitle style={{display: 'flex', justifyContent: 'center'}} >{`Are you sure you want to change the following addons of the ${customer.company}?`}</DialogTitle>

                <DialogContent >              
                    <List 
                        fluid 
                        columns={columns} 
                        height="240" 
                        data={data} 
                        listRowType={AddonConfirmationRow}
                        sharedData={{companies: companies}} />
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={() => onDialogSave()} >
                        Save
                    </Button>                
                    <Button size="large" variant="outlined" onClick={() => onDialogClose()} >
                        Discard
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


export default AddonConfirmationDialog;
