import React from 'react';
import "./TextInput.css";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	input: {
		fontSize: "12px",
		margin: "4px 0 0 0",
		background: "#fdfdfd",
		height: "80%",
		borderRadius: 0,
		border: "1px solid #2d9ff7",
	},
	error: {
		border: `1px solid ${theme.palette.taimer.error}`,
		color: theme.palette.taimer.error
	}
});

class TextInput extends React.Component {
	static defaultProps = {
		className: "",
		initValue: "",
		placeholder: "",
		error: false,
		focusOnMount: false,
		textAlign: "left",
		onInputListener: () => {},
		onFocus: () => {},
		onBlur: () => {},
		onKeyUp: () => {},
		onEnter: () => {},
		classNames: {
			error: "error",
			noError: ""
		}
	};


	constructor(props) {
		super(props);

		this.inputRef = React.createRef();
		this.onInput  = this.onInput.bind(this);
		this.onKeyUp  = this.onKeyUp.bind(this);
		this.state    = { value: props.initValue };
	}


	componentDidMount() {
		if(this.props.focusOnMount)
			this.inputRef.current.focus();
	}


	onInput(event) {
		event.persist();

		this.setState({
			value: event.target.value
		}, () => {
			if(typeof this.props.onInputListener == "function")
				this.props.onInputListener(event.target.value, event);
		});
	}


	setValue(value) {
		this.setState({ value: value });
	}


	getValue() {
		return this.state.value;
	}


	focus() {
		this.inputRef.current && this.inputRef.current.focus();
	}


	select() {
		this.inputRef.current && this.inputRef.current.select();
	}


	onKeyUp(event) {
		this.props.onKeyUp(event);

		if(event.keyCode === 13)
			this.props.onEnter(event);
	}


	render() {
		const className = `textInput ${this.props.classes.input} ${this.props.className} ${this.props.error ? this.props.classes.error : ""}`;

		return (
			<input
				type={this.props.type || "text"}
				className={className}
				ref={this.inputRef}
				value={this.state.value}
				onChange={this.onInput}
				onKeyUp={this.onKeyUp}
				onFocus={this.props.onFocus}
				onBlur={this.props.onBlur}
				placeholder={this.props.placeholder}
				style={{ textAlign: this.props.textAlign }} />
		);
	}
}

export default withStyles(styles, {withTheme: true})(TextInput);
