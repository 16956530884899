import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateRange from './DateRange';
import DefinedRange from './DefinedRange';
import { format } from 'date-fns';
import { findNextRangeIndex, generateStyles } from '../utils.js';
import classnames from 'classnames';
import coreStyles from '../styles';
import CalendarIcon from '@material-ui/icons/DateRange';
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import '../styles.css';
import '../theme/default.css';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { InputLabel, FormControl } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { getDefaultStaticRanges } from '../defaultRanges';
import { SettingsContext } from './../../../../SettingsContext';

class DateRangePicker extends Component {
  static contextType = SettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      focusedRange: [findNextRangeIndex(props.ranges), 0],
      open: false
    };
    this.styles = generateStyles([coreStyles, props.classNames]);

    let regExp = new RegExp("([a-zA-Z])");
    this.props.dateFormat.split("").map(char => {
      if(regExp.exec(char) === null) {
        this.delimeter = char;
      }
      return null;
    });

    this.formatArray = this.props.dateFormat.split(this.delimeter).map(el => el.toLowerCase());
  }

  onDateChange(dateType, event) {
    if(event.target.value.indexOf("_") == -1 && event.target.value != "") {
      const dateParts = event.target.value.split(this.delimeter);
      const date = new Date(dateParts[this.formatArray.indexOf('yyyy')], dateParts[this.formatArray.indexOf('mm')]-1, dateParts[this.formatArray.indexOf('dd')]);
      const range = this.props.onInputChange(dateType, date);
      if(this.state.open) {
        this.dateRange.updatePreview(range)
        this.handleBlur();
      }
    }
  }

  handleToggle = () => {
    this.setState({open: !this.state.open});
  }

  handleFocus() {
    this.setState({open: true});
  }

  handleBlur() {
    this.setState({open: false});
  }

  handleChange(date) {
    this.props.onChange(date);
    this.handleBlur();
  }

  render() {
    const { className, disabled, dateFormat } = this.props;
    const { focusedRange } = this.state;
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe(this.props.dateFormat.toLowerCase());

    const dateOptions = {
      weekStartsOn: this.context.calendar.startOfWeek
    }

    const regExp = new RegExp("([a-zA-Z])");
    const mask = dateFormat.split("").map(char => {
      if(regExp.exec(char) === null)
        return char;

      return /\d/;
    });

    return (
      <ClickAwayListener onClickAway={() => this.handleBlur()}>
      <div className={classnames(this.styles.dateRangePickerWrapper, `daterange ${className}`, disabled && "disabled")}>
        <div>
        {this.props.mode != "list" ? (<FormControl className={"formControl"} variant="filled">
          <InputLabel
            ref={ref => {
              this.labelRef = ReactDOM.findDOMNode(ref);
            }}
            shrink={true}>
            {this.props.label}
          </InputLabel>
          <div className="inputWrapper">
            <MaskedInput
              disabled={this.props.disabled}
              mask={mask}
              className="datePickerInput"
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(this.props.ranges[0].startDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("start", e)}
              onFocus={(e) => this.handleFocus(e)} />
            <span className="date-separator">-</span>
            <MaskedInput
              disabled={this.props.disabled}
              mask={mask}
              className="datePickerInput"
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(this.props.ranges[0].endDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("end", e)}
              onFocus={(e) => this.handleFocus(e)} />
           <CalendarIcon
                alt="Date picker"
                onClick={(e) => this.handleToggle(e)}
            />
          </div>
        </FormControl>
        ) : (
          <div className="inputWrapper">
            <MaskedInput
              disabled={this.props.disabled}
              mask={mask}
              className="datePickerInput"
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(this.props.ranges[0].startDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("start", e)}
              onFocus={(e) => this.handleFocus(e)} />
            <span>-</span>
            <MaskedInput
              disabled={this.props.disabled}
              mask={mask}
              className="datePickerInput"
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(this.props.ranges[0].endDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("end", e)}
              onFocus={(e) => this.handleFocus(e)} />
           <CalendarIcon
                alt="Date picker"
                onClick={(e) => this.handleToggle(e)}
            />
          </div>
        )}
        </div>

        <div className={"calendarView" + ((this.state.open && !this.props.disabled) ? '' : ' hidden')}>
          <DefinedRange
            staticRanges={getDefaultStaticRanges(dateOptions)}
            focusedRange={focusedRange}
            onPreviewChange={value => this.dateRange.updatePreview(value)}
            {...this.props}
            range={this.props.ranges[focusedRange[0]]}
            className={undefined}
            onRangeSelected={(e) => this.handleBlur(e)}
          />
          <DateRange
            onRangeFocusChange={focusedRange => this.setState({focusedRange})}
            focusedRange={focusedRange}
            {...this.props}
            ref={t => (this.dateRange = t)}
            className={undefined}
            onRangeSelected={(e) => this.handleBlur(e)}
          />
        </div>
      </div>
      </ClickAwayListener>
    );
  }
}

DateRangePicker.defaultProps = {
  months: 2,
  direction: "horizontal",
  dateFormat: "dd/mm/yyyy",
  mode: "input",
  disabled: false
};

DateRangePicker.propTypes = {
  ...DateRange.propTypes,
  ...DefinedRange.propTypes,
  className: PropTypes.string,
};

export default DateRangePicker;
