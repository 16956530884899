import React from 'react';

export const SettingsContext = React.createContext({
    userObject: {
        usersId: 1,
        fullname: "Mikko Luostarinen",
        abbreviation: "ML",
        role: "Developer",
        companies_id: "2",
        language: 'en-GB',
        hourSymbol: 'h',
        dateFormat: "YYYY-MM-DD",
        timeFormat: "HH:MM:SS",
        datetimeFormat: "YYYY-MM-DD LT",
        color: "ffffff"
    },
    taimerAccount: {
        numberFormat: 'fi-FI',
        currency: 'EUR',
        symbolPosition: 'start', //'start'|'end'
        symbol: '€',
        ourCompanyLang: 'en', //company language selection temporarily defined here.
    },
    calendar: {
        clock: 0, // 0 = 12hr, 1 = 24hr clock
        startOfWeek: 0, // 0 sunday, 1 monday
    },
    timeTracker: {
        workhour_accuracy: 15,
        slots: 4,
        startTime: [4, 0, 0],
        endTime: [22, 0, 0],
        isSalomaa: false,
        resourcingEnabled: true,
        workday_length: 8
    },
    privileges: {
        "calendar" : 1,
        "workhours": 1,
        "invoices": 1,
        "reports": 1
    },
    functions: {
        updateView: () => {}
    },
    allAddOns: {}
});
