import React from 'react';
import ListCell from "./../ListCell";
// import DatePicker from "./../../general/DatePicker";
import { DatePicker } from './../../general/react-date-range/src';
import { format } from "date-fns";
import './../../general/react-date-range/src/styles.css';
import './../../general/react-date-range/src/theme/default.css';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { SettingsContext } from './../../SettingsContext';

class DateCell extends React.Component {

	static contextType = SettingsContext;

	constructor(props) {
		super(props);

		this.datePicker = React.createRef();
		this.listCell 	= React.createRef();

        this.state = { date: props.value, editable: props.editable };

        this.handleSelect 	 = this.handleSelect.bind(this);
        this.handleInput 	 = this.handleInput.bind(this);
        this.fastEditHandler = this.fastEditHandler.bind(this);
        this.focusOnEditor 	 = this.focusOnEditor.bind(this);
	}

    handleSelect(date) {
    	date = format(date, 'YYYY-MM-DD');

    	this.props.onEdited(...(this.props.onEdited.length === 1 ? [date] : [this.props.name, date]));

		this.listCell.current.closeEdit();
	}

	handleInput(evt, date) {
		date = format(date, 'YYYY-MM-DD');

		this.props.onEdited(...(this.props.onEdited.length === 1 ? [date] : [this.props.name, date]));

		if(this.props.closeOnComplete)
			this.listCell.current.closeEdit();
	}

	fastEditHandler(open) {
		open ? this.datePicker.current.setState({open: true}) : this.listCell.current.closeEdit();
	}

	focusOnEditor() {
		this.datePicker.current.focusOnInput()
	}

	render() {
		const { userObject } = this.context;
		const dateFormat 	 = this.props.dateFormat ? this.props.dateFormat : userObject.dateFormat;
		const ListCellType   = this.props.listCellType;
		const listCellProps  = Object.assign({
			ref: this.listCell,
			owner: this,
			width: this.props.width,
			value: (this.props.value == "1970-01-01" || this.props.value == "0000-00-00" || this.props.value == "0000-00-00 00:00:00") ? "" : format(this.props.value, dateFormat.toUpperCase()),
			editable: this.props.editable,
			onEnterEditMode: () => this.fastEditHandler(true)
		}, this.props.listCellProps);

		listCellProps.value = (listCellProps.value == "Invalid Date") ? "" : listCellProps.value;

		const datePickerValue = (this.props.value == "0000-00-00") ? new Date() : this.props.value;
console.log(this.props.listCellProps)
		return (
			<ClickAwayListener onClickAway={() => this.listCell.current.closeEdit()}>
				<ListCellType {...listCellProps}>
					<DatePicker
						mode="list"
						ref={this.datePicker}
						date={datePickerValue}
						dateFormat={dateFormat}
	                    onChange={this.handleSelect}
	                    onInputChange={this.handleInput}
	                    onClose={this.fastEditHandler}
	                    closeCalendarOnComplete={this.props.closeCalendarOnComplete}
	                    closeOnComplete={this.props.closeOnComplete}
	                    inputOnEnter={(e) => {
	                    	this.props.onEnter(e);
	                    	this.listCell.current.closeEdit();
	                    }} />
				</ListCellType>
			</ClickAwayListener>
		);
	}
}

DateCell.defaultProps = {
	width: 100,
	value: "1970-01-01",
	editable: true,
	onEdited: () => {},
	onEnter: () => {},
	closeOnComplete: true,
	closeCalendarOnComplete: false,
	listCellProps: {},
	listCellType: ListCell
};

export default DateCell;