import React from 'react';
import PropTypes from "prop-types";

/* material ui */
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { withStyles } from "@material-ui/core/styles";
import { Menu, MenuList, MenuItem } from '@material-ui/core';
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const styles = theme => ({
    arrowdropdown: {
        top: "50%",
        right: "6px",
        width: "18px",
        height: "18px",
        position: "absolute",
        "margin-top": "-9px",
        "border-radius": "18px",
        "background-color": "#f7f8fc",
        "cursor": "pointer",
    
        "& path": {
          color: "#dcdee6"
        }
    
      },
      button: {}
});

export class ContextSubMenu extends React.Component {
    state = {
        "open": false,
    }

    constructor(props) {
        super(props);
        this.anchorEl = React.createRef();
        //this.handleClose = this.handleClose.bind(this);
    }

    onClick = () => {
        const { open } = this.state;        

        this.setState({open: !open});

        this.props.onOpen && this.props.onOpen();
    }

    handleClose = () => {
        this.setState({open: false});

        this.props.onClose && this.props.onClose();
    }

    render() {
        const { title, children } = this.props;
        const { open } = this.state;        

        return (
            <React.Fragment>
                <MenuItem buttonRef={this.anchorEl} onClick={this.onClick}>{title} <ArrowRightIcon /></MenuItem>
                
                <Menu onClose={this.props.onClose} open={open} anchorEl={this.anchorEl.current} anchorOrigin={{ vertical: 'top', horizontal: 'right',}}>
                    {React.Children.map(children, child => {
                        if (!child) return undefined;
                        return React.cloneElement(child, {
                            onClose: this.handleClose,
                            onClick: child.props.onClick ? (evt) => { 
                                !child.props.noClose && this.handleClose(evt);
                                child.props.onClick(evt);
                        } : undefined});
                    })}
                </Menu>

            </React.Fragment>
        );
    }
}
 
class ContextMenu extends React.Component {
    state = {
        "open": false,
        submenuOpen: false,
    }
    constructor(props) {
        super(props);
        this.anchorEl = React.createRef();
        this.handleClose = this.handleClose.bind(this);
    }
    submenuOpen = () => {
        this.setState({submenuOpen: true});
    }
    handleClose (event) {
        if (event && event.target && this.anchorEl.contains(event.target)) {
          return;
        }

        if (this.state.submenuOpen) {
            this.setState({submenuOpen: false});
            return;
        }

        this.props.onClose && this.props.onClose(event);
        this.setState({open: false}); 
    };
    handleToggle = () => {
        this.setState({"open": !this.state.open});
    }
    render() {
        
        const { open } = this.state;
        const { classes, label, children, placement, size, buttonProps, popperProps, variant, noExpandIcon, disablePortal, ...rest} = this.props;
        
        const buttonClasses = (buttonProps && buttonProps.className || "") + ` ${open && "open"}`;
        
        return (
            <div {...rest} onClose={this.handleClose}>
                <Button
                    classes={{root: classes.button}}
                    size={size}
                    disableRipple={true}
                    variant={variant} 
                    buttonRef={node => { this.anchorEl = node; }} 
                    aria-owns={open ? 'menu-list-grow' : undefined} 
                    aria-haspopup="true" 
                    onClick={this.handleToggle} 
                    {...buttonProps}
                    className={"context-menu " + buttonClasses }
                    >
                    {label}
                    {!noExpandIcon && (<ArrowDropDown className={this.props.classes.arrowdropdown}/>)}
                </Button>
                <Popper open={open} anchorEl={this.anchorEl} placement={placement} transition disablePortal={disablePortal} {...popperProps}>
                    {({ TransitionProps, placement }) => (
                    <Grow  {...TransitionProps}  id="menu-list-grow" style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <MenuList>
                                    {React.Children.map(children, child => {
                                        if (!child) return undefined;
                                        return React.cloneElement(child, {
                                            onClose: this.handleClose,
                                            onOpen: this.submenuOpen,
                                            onClick: child.props.onClick ? (evt) => { 
                                                !child.props.noClose && this.handleClose(evt);
                                                child.props.onClick(evt);
                                        } : undefined});
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                    )}
                </Popper>
            </div>
        );
    }
};
ContextMenu.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    variant: PropTypes.string,
};
ContextMenu.defaultProps = {
    variant: "text",
    disablePortal: true,
}
export default withStyles(styles, { withTheme: true })(ContextMenu);