import React from 'react';

class FocusGroup extends React.Component {
	static defaultProps = {
		onEnter: () => true,
		onCtrlS: () => {},
		focusOnInit: false,
		columnOrder: []
	};


	constructor(props) {
		super(props);

		this.references = [];

		this.deleteReferences 	= this.deleteReferences.bind(this);
		this.focusElement 	  	= this.focusElement.bind(this);
		this.focusElementByName = this.focusElementByName.bind(this);
	}


	componentDidMount() {
		if(this.props.focusOnInit)
			this.focusElement(0);
	}


	deleteReferences() {
		for(let i in this.references)
			delete this.references[i];

		this.references = [];
	}


	focusElement(index, force = false) {
		if(index >= this.references.length)
			return false;

		const cell = this.references[index].current;

		if(!cell.hasOwnProperty("__getListCell") || !force && (typeof cell.__getListCell !== "function" || !cell.__getListCell().isEditable() || !cell.__getListCell().isInEditMode()))
			return this.focusElement(++index);

		cell.__getListCell().focus();

		return true;
	}


	focusElementByName(name) {
		const index = this.props.columnOrder.findIndex(c => c === name);

		if(index === -1)
			return;

		this.focusElement(index, true);
	}


	render() {
		this.deleteReferences();

		return (<React.Fragment>
			{React.Children.map(this.props.children, (child, index) => {
				const reference = React.createRef();

				this.references.push(reference);

				return React.cloneElement(child, {
					ref: reference,
					listCellProps: { ...(child.props.hasOwnProperty("listCellProps") ? child.props.listCellProps : {}), onCtrlS: () => this.props.onCtrlS() },
					onEnter: (e) => {
						if(!this.props.onEnter()) // Kind of irrelevant without the tab functionality originally planned.
							return;

						if(e.type === "keydown") {
							const anonFocus = () => {
								this.focusElement(index + 1);

								document.removeEventListener("keyup", anonFocus);
							};

							document.addEventListener("keyup", anonFocus);
						} else {
							this.focusElement(index + 1);
						}
					}
				});
			})}
		</React.Fragment>);
	}
}

export default FocusGroup;