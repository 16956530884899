import React from 'react';
import ListCell from "./../ListCell";
import Select, { components } from 'react-select';
import Creatable from "react-select/lib/Creatable";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';


const DropdownIndicator = (props) => {
  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

const IndicatorsContainer = (props) => {
  return (
    <div style={{}}>
      <components.IndicatorsContainer {...props}/>
    </div>
  );
};


class AutoCompleteCell extends React.Component {
	static defaultProps = {
		editable: true,
		listCellType: ListCell,
		listCellProps: {},
		multiple: false,
		searchable: true,
		openMenuOnEdit: true,
		closeMenuOnSelect: true,
		tabSelectsValue: true,
		menuPosition: "absolute",
		menuPortalTarget: false,
		allowCreate: false,
		value: {},
		autoCompleteData: [],
		autoCompleteLabelKey: "name",
		onEdited: () => {},
		onEnter: () => {},
		width: 200
	};


	constructor(props) {
		super(props);

		this.listCell 	  = React.createRef();
		this.autoComplete = React.createRef();

		this.focusOnEditor = this.focusOnEditor.bind(this);
	}


	focusOnEditor() {
		this.autoComplete.current.focus();
	}


	render() {
		// If a non-object value is given as a prop, but autoCompleteData has objects, find the object with that id.
		// TODO: add a props that lets you define the relevant key.
		let value = (typeof this.props.value !== "object" && this.props.autoCompleteData.length > 0 && typeof this.props.autoCompleteData[0] === "object") ? this.props.autoCompleteData.find(o => o.id === this.props.value) : this.props.value;

		if(!value || value === undefined || value === null)
			value = {};

		const ListCellType  = this.props.listCellType;
		const listCellProps = Object.assign({}, {
			owner: this, 
			ref: this.listCell, 
			width: this.props.width, 
			value: value, 
			editable: this.props.editable,
			onEnterEditMode: () => {
				// if(!this.props.listCellProps.hasOwnProperty("noInitFocus") || !this.props.listCellProps.noInitFocus)
					this.autoComplete.current.focus()
			}
		}, this.props.listCellProps);

		let addedProps = {};

		if(!this.props.listCellProps.inEditMode)
			addedProps.menuIsOpen = this.props.openMenuOnEdit;

		const SelectType = !this.props.allowCreate ? Select : Creatable;

		return (
			<ListCellType {...listCellProps}>
				<SelectType
					ref={this.autoComplete}
					components={{ DropdownIndicator: DropdownIndicator, IndicatorsContainer: IndicatorsContainer, ...this.props.components }}
					// autoFocus={listCellProps.hasOwnProperty("initInEditMode") && listCellProps.initInEditMode && !listCellProps.noInitFocus}
					isSearchable={this.props.searchable}
					isMulti={this.props.multiple}
					value={value}
					tabSelectsValue={this.props.tabSelectsValue}
					closeMenuOnSelect={this.props.closeMenuOnSelect}
					options={this.props.autoCompleteData.filter(f => f.deleted != 1).map(el => {
						el['label'] = el.hasOwnProperty(this.props.autoCompleteLabelKey) ? el[this.props.autoCompleteLabelKey] : "(no label)";
						return el;
					})}
					menuPosition={this.props.menuPosition}
					menuPortalTarget={this.props.menuPortalTarget}
					className="autoCompleteInCell"
					classNamePrefix="autoCompleteInCell"
					openMenuOnFocus={true}
					placeholder={this.props.placeholder}
					{...addedProps}
					onBlur={() => this.listCell.current.closeEdit()}
					onKeyDown={e => {
						if(e.key !== "Enter")
							return;

						this.props.onEnter(e);
					}}
					onChange={value => {
						if(this.props.allowCreate)
							value = this.props.multiple ? value.map(v => { return {...v, new: v.__isNew__ } }) : {...value, new: value.__isNews__ };

						this.listCell.current.closeEdit();
						this.props.onEdited(value);
					}} />
			</ListCellType>
		);
	}
}

export default AutoCompleteCell;
