import React, { Component } from 'react';
import logo from './logo.svg';
import './index.css';

/* local components */
import Login from './login/Login';
import AccountList from './accounts/AccountList';

/* mui theme */
import { TaimerTheme } from './TaimerTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';

class App extends Component {
   
    render() {
        const view = !sessionStorage.adminToken ? <Login /> : <AccountList />;
            
        return (
            <MuiThemeProvider theme={TaimerTheme}>
                <div id="App">
                    {view}
                </div>
            </MuiThemeProvider>
        );
  }
}

export default App;
