let Utils = {
	objectsAreEquivalentSimple: (a, b) => {
		if(a === b)
			return true;

		let aProps = Object.getOwnPropertyNames(a);
		let bProps = Object.getOwnPropertyNames(b);

		if(aProps.length !== bProps.length)
			return false;

		for(let i of aProps)
			if(!b.hasOwnProperty(i) || b[i] !== a[i])
				return false;

		for(let i of bProps)
			if(!a.hasOwnProperty(i) || a[i] !== b[i])
				return false;

		return true;
	},

	// Relies on all objects in sets a and b having an 'id' field.
	dataObjectArraysAreEquivalent: (a, b) => {
		for(let aObj of a) {
			let toCompare = b.find(bElement => bElement.id === aObj.id);

			if(toCompare === undefined || !Utils.objectsAreEquivalentSimple(aObj, toCompare))
				return false;
		}

		for(let bObj of b) {
			let toCompare = a.find(aElement => aElement.id === bObj.id);

			if(toCompare === undefined || !Utils.objectsAreEquivalentSimple(bObj, toCompare))
				return false;
		}

		return true;
	},

	arrayIntersect: (arrOfArrs) => {
        if(arrOfArrs.length === 0)
            return [];

        let argL          = arrOfArrs.length;
        let arrs          = [];
        let shortestIndex = 0;
        let shortest      = 0;
        let intersection  = [];

        for(let i = 0; i < argL; i++) {
            if(!Array.isArray(arrOfArrs[i]))
                arrOfArrs[i] = Array.from(arrOfArrs[i]);

            arrs.push(arrOfArrs[i]);

            if(shortest === 0 || arrOfArrs[i].length < shortest) {
                shortest      = arrOfArrs[i].length;
                shortestIndex = i;
            }
        }

        for(let ai = 0; ai < arrs[shortestIndex].length; ai++) {
            let inAll = true;

            for(let i = 0; i < arrs.length; i++) {
                if(i === shortestIndex)
                    continue;

                if(arrs[i].indexOf(arrs[shortestIndex][ai]) === -1) {
                    inAll = false;
                    break;
                }
            }

            if(inAll)
                intersection.push(arrs[shortestIndex][ai]);
        }

        return intersection;
    },

	intRange: (a, b, inc = +1) => {
		if(inc === 0)
			throw new Error("inc can not be 0.");
		if(a === b)
			return [a];

		inc = a < b ? Math.abs(inc) : inc > 0 ? -1 * inc : inc;

		let r = [];

		while(true) {
			r.push(a);

			a += inc;

			if((inc > 0 && a > b) || (inc < 0 && a < b))
				break;
		}

		return r;
	},

	pageRange: (curr, max, aroundCurr = 2, aroundEnds = 2) => {
		let pages 	 	   = [];
		let insertInterval = false;

		for(let i = 1; i <= max; i++) {
			if(i === 1 || i <= 1 + aroundEnds || i >= max - aroundEnds || (i >= curr - aroundCurr && i <= curr + aroundCurr)) {
				pages.push(i);

				insertInterval = true;
			} else if(insertInterval) {
				pages.push("...");

				insertInterval = false;
			}
		}

		return pages;
	},

	pageRangeNine: (cur, max) => {
		if(cur === 1 && cur === max)
			return [1];

		if(max <= 9)
			return Utils.intRange(1, max);

		let start = (cur > 6 && max > 10) ? [1, 2] : Utils.intRange(1, 6);
		let end   = (max - cur < 6 && max > 10) ? Utils.intRange(max - 5, max) : [max - 1, max];
		let mid   = (start.length === 2 && end.length === 2) ? ["...", ...[cur -1, cur, cur + 1], "..."] : ["..."];

		return [...start, ...mid, ...end];
	},

	addThousandSeparator: (n) => {
		if(!n)
	        return "";
	    var sign = "";
	    n = "" + n;
	    if(n.indexOf("-") === 0) {
	       n = n.slice(1);
	        sign = "-";
	    }
	    var rx=  /(\d+)(\d{3})/;
	    return String(n).replace(/^\d+/, function(w){
	        while(rx.test(w)){
	            w= w.replace(rx, '$1 $2');
	        }
	        return sign + w;
	    });
	},

	// Find an object defined by the rule in f (f.ex. (element) => element.id === "13"); a is an array containing the data;
	// childrenPropertyName defines the name of the property that has the object's children.
	findRecursively: (a, f, childrenPropertyName = "children") => {
		let found = undefined;

		for(let i = 0; i < a.length && found === undefined; i++)
			found = f(a[i]) ? a[i] : Utils.findRecursively(a[i][childrenPropertyName], f, childrenPropertyName);

		return found;
	},

	makeMap: (arr, key) => {
		const map = {};

		for(let e of arr)
			map[e[key]] = e;

		return map;
	},

	treeFormatDataForList: (data, parentKey = "parentId") => {
		data = (Array.isArray(data)) ? data : [data];

		if(data.length === 0)
			return;

		const parentParentIdValues = [undefined, null, -1, 0, "0", "-1"];

		let map 	  = {};
		let finalData = [];
		let idOrder   = [];

		data.forEach(el => {
			let tempObject 			= {};
			tempObject.data 		= Object.assign({}, el);
			tempObject.children 	= [];
			map[tempObject.data.id] = tempObject;

			idOrder.push(el.id);
		});

		let allIds = [...Object.keys(map), ...Object.keys(map).map(k => parseInt(k))];

		for(let i in map) {
			let temp = map[i];

			if(!temp.data.hasOwnProperty(parentKey) || allIds.indexOf(temp.data[parentKey]) === -1 || parentParentIdValues.indexOf(temp.data[parentKey]) > -1)
				continue;

			map[temp.data[parentKey]].children.push(temp);
		}

		// Form an array where there are no children on the first level, and children only occur under their parent's children property; TODO: unless the child's parent is not in this set.
		for(let i in map) {
			let el = map[i];

			Object.defineProperty(el, "pristineData", { value: el.data, writable: false });

			if(el.data.hasOwnProperty(parentKey) && parentParentIdValues.indexOf(el.data[parentKey]) === -1) // el.data[parentKey] !== undefined && el.data[parentKey] !== null)
				continue;

			finalData.push(map[i]);
		}

		finalData.sort((a, b) => idOrder.indexOf(a.data.id) - idOrder.indexOf(b.data.id));

		return finalData;
	},

	formatDataForList: (data) => {
		data = (Array.isArray(data)) ? data : [data];

		if(data.length === 0)
			return;

		let finalData = [];
		let idOrder   = [];

		for(let i in data) {
			let tempObject = {};

			tempObject.data = Object.assign({}, data[i]);
			tempObject.children = [];

			finalData.push(tempObject);

			idOrder.push(data[i].key || data[i].id);
		}

		finalData.sort((a, b) => idOrder.indexOf(a.data.key || a.data.id) - idOrder.indexOf(b.data.key || b.data.id));

		return finalData;
	}
};

export default Utils;