import React from "react";
import TextInput from "../general/TextInput";
import Utils from "../general/Utils.js";
import "./PageSelector.css";

class PageSelector extends React.Component {
	constructor(props) {
		super(props);

		this.state = { page: 1 };

		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.setPage  = this.setPage.bind(this);
	}


	prevPage() {
		this.setPage(parseInt(this.state.page, 10) - 1);
	}


	nextPage() {
		this.setPage(parseInt(this.state.page, 10) + 1);
	}


	setPage(page) {
		if(page < 1)
			page = 1;
		else if(page > this.props.pageCount)
			page = Math.max(1, this.props.pageCount);

		this.props.onPageChange(page);

		this.setState({ page: page });
	}


	getPage() {
		return this.state.page;
	}


    componentDidUpdate(prevProps, prevState) {
        // If the page was changed "from the outside".
        if(this.props.controlPage && this.props.page !== prevState.page) {
            this.setState({ page: this.props.page }); 
        } 
    }


	render() {
		const pageRange = Utils.pageRangeNine(this.state.page, this.props.pageCount);

		return (
			<div className="pageSelector">
				<div className="button arrow" onClick={() => {
					const newPage = this.state.page - this.props.skipN;
					this.setPage(newPage < 1 ? 1 : newPage);
				}}><span>«</span></div>
				<div className="button arrow" onClick={this.prevPage}><span>‹</span></div>

				{pageRange.map((n, index) => {
					return <div className={n == this.state.page ? "button active" : "button"} key={index} onClick={n !== "..." ? e => this.setPage(n) : () => {}}><span>{n}</span></div>
				})}

				<div className="button arrow" onClick={this.nextPage}><span>›</span></div>
				<div className="button arrow" onClick={() => {
					const newPage = this.state.page + this.props.skipN;
					this.setPage(newPage > this.props.pageCount ? this.props.pageCount : newPage);
				}}><span>»</span></div>
			</div>
		);
	}
}

PageSelector.defaultProps = {
	pageCount: 9999,
    controlPage: false,
	skipN: 10,
	onPageChange: () => {},
	pageNumbersToShowAroundCurrentPageNumber: 1,
	maxStretchBeginningAndEnd: 1
};

export default PageSelector;
