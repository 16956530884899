import React from 'react';

/* material ui */
import { Button, FormControl, InputLabel, ListSubheader, MenuItem, Select, TextField } from '@material-ui/core';

/* icons */
import { Clear } from '@material-ui/icons';


/*data */
import DataHandler from "./../general/DataHandler";

/* css */
import './AccountDialog.css';
 
class AccountDialog extends React.Component {
    state = {
        superadmins: [],
        admins: [],        
        users: [],
        users_id: "",
        dropDownOpen: false,
        searchString: '',
    }
    
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.loginAs = this.loginAs.bind(this);
    }
    componentWillMount() {
        this.updateComponentData();
    }
    
    updateComponentData() {
        const { account } = this.props;
        DataHandler.get({url: `accounts/${account.id}/users`}).done( e => {
            let superadmins = [], admins = [], users = [];
            e.forEach(user => {
                switch (user.adminlevel) {
                    case 1:
                        superadmins.push(user);
                        break;
                    case 2:
                        admins.push(user);
                        break;
                    default:
                        users.push(user);
                }
            })
            this.setState({superadmins, admins, users})
        });
    }
    onChange = (evt) => {
        let {name, value} = evt.target;
        this.setState({[name]: value});
    }
    loginAs = () => {
        const { account } = this.props;
        const { users_id } = this.state;
        
        DataHandler.get({url: `accounts/${account.id}/users/${users_id}/login`}).done( response => {
            sessionStorage.setItem('taimerVersionId',response.version_id);
            window.location = response.url;
        });
    }

    searchUsers = (event) => {
        this.setState({searchString: event.target.value});
    }
    
    render () {
        const { account } = this.props;
        const { superadmins, admins, users, users_id, searchString } = this.state; 

        const filteredSuperAdmins = superadmins.filter(r => 
            searchString == '' || r.label.toLowerCase().includes(searchString.toLowerCase())
        );

        const filteredAdmins = admins.filter(r => 
            searchString == '' || r.label.toLowerCase().includes(searchString.toLowerCase())
        );

        const filteredUsers = users.filter(r => 
            searchString == '' || r.label.toLowerCase().includes(searchString.toLowerCase())
        )

        return (
            <div id="account-dialog">
                <div className="dialog">
                    <h1>{account.company} ({account.folder})</h1>
                    this action does not log out actual user

                    <FormControl variant="outlined" className="users-dropdown" fullWidth>
                        <InputLabel htmlFor="users-dropdown">Users</InputLabel>
                        <Select
                        
                            value={this.state.users_id}
                            onOpen={() => this.setState({dropDownOpen: true})}
                            onClose={(_) => {}}
                            onChange={(e) => {
                                if (e.target.value) {
                                    this.setState({users_id: e.target.value})
                                    this.setState({dropDownOpen: false})
                                }
                                else
                                    this.setState({dropDownOpen: true})
                            }}
                            open={this.state.dropDownOpen}
                            inputProps={{
                                name: 'Users',
                                id: 'users-dropdown',
                            }}
                        >
                            <TextField
                                id="user-search"
                                className="usersearch"
                                label="Search users"
                                type="search"
                                fullWidth
                                placeholder="Search users"
                                margin="normal"
                                autoFocus={true}
                                onChange={(e) => this.searchUsers(e)}
                            />
                            <ListSubheader className={`subheader ${filteredSuperAdmins.length > 0 ? '' : 'hidden'}`} disableSticky inset>Administrator in every company</ListSubheader>
                            {filteredSuperAdmins.map(row => 
                                <MenuItem value={row.id} >{row.label}</MenuItem>
                            )}
                            <ListSubheader className={`subheader ${filteredAdmins.length > 0 ? '' : 'hidden'}`} disableSticky inset>Administrator of some companies</ListSubheader>
                            {filteredAdmins.map(row => 
                                <MenuItem title={`Admin in: ${row.admin_companies}`} value={row.id}>{row.label}<div className="admin_companies">{row.admin_companies}</div></MenuItem>
                            )}
                            <ListSubheader className={`subheader ${filteredUsers.length > 0 ? '' : 'hidden'}`} disableSticky inset>No administrator rights</ListSubheader>
                            {filteredUsers.map(row => 
                                <MenuItem value={row.id}>{row.label}</MenuItem>
                            )}                            

                        </Select>
                    </FormControl>
                    <Button size="large" className="login" color="primary" onClick={this.loginAs}>Login</Button>
                    <Button className="close-button" onClick={this.props.toggleDialog}><Clear /></Button>
                </div>
            </div>
        );

    }

}

export default AccountDialog;