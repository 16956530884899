import {  createMuiTheme } from '@material-ui/core/styles';

const colors = {
    primary: '#2d9ff7',
    error: "#FF5722",
    darkred: "#d0021b",
    salmon: "#f7548f",
    darkblue: "#6b7897",
    darkerblue: "#34495e",
    darkgrey: "#83838b",
    white3: "#fff",
    white6: "#ffffff",
    whitef9: "#f9f9f9",
    lightgrey: "#dde3e8",
    green: "#50e3c2",
}

const disabledStyle = {
    //border: 'solid 1px #dde3e8',
    backgroundColor: `${colors.whitef9} !important`
}

const fontSize = 14;

export const TaimerTheme = createMuiTheme({
    palette: {
        taimer: colors,
        primary: {
            main: colors.primary
        },
    },
    disabledStyle,
    typography: {
        useNextVariants: true,
        fontFamily: "'Open Sans', sans-serif",
        fontSize,
    },
    overrides: {
        MuiMenuItem: {
            root: {
                fontSize,
                color: colors.darkblue
            }
        },
        MuiChip: {
            root: {
                fontSize: 12,
                backgroundColor: colors.darkblue,
                color: '#fff',
                "&.square": {
                    borderRadius: 4,
                },
            },
            deleteIcon: {
                fill: '#fff',
                fontSize: 20,
            },
        },
        MuiInputBase: {
            root: {
                fontSize,
                "&:before": {
                    borderBottom: "0px !important"
                },
                "&:after": {
                    borderBottom: "0px !important"
                },
                backgroundColor: "#fff !important", // ¯\_(ツ)_/¯
                border: `1px solid ${colors.lightgrey}`,
                borderRadius: "4px",
                color: '#34495e',
            },
            error: {
                border: `1px solid ${colors.error}` ,
            },
            disabled: disabledStyle,
            input: {
                fontSize: 12,
            },
            adornedStart: {
                boxSizing: 'border-box', /* theres a bug with sizing when adornment is used */
                "& p": {
                    fontSize: 12,
                    paddingTop: 18,
                },
                "& input": {
                    marginLeft: -4,
                },
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: 12,
                color: colors.darkgrey,

                "&.select": {
                    color: `${colors.darkgrey} !important`,
                }
            },
            focused: {
                color: colors.darkgrey
            },
            error: {
                color: colors.error
            },
            filled: {
                color: colors.darkgrey
            },
        },
        MuiSelect: {
            root: {
                fontSize,
                borderRadius: 4,
                backgroundColor: `${colors.white3} !important`,
                color: colors.darkblue,

                "&>svg": {
                    position: "absolute",
                    right: "6px",
                    top: "50%",
                    marginTop: "-9px",
                    backgroundColor: "#f7f8fc",
                    width: "18px",
                    height: "18px",
                    borderRadius: "18px",
                },

                "& path": {
                    color: "#dcdee6"
                }
            },
            filled: {
                "&>div": {
                    borderRadius: 4,
                    backgroundColor: colors.white3
                },
                "&:active": {
                    borderRadius: 4,
                    backgroundColor: colors.white3
                },
                // backgroundColor: colors.primary,
                backgroundColor: colors.white3,
                // border: "1px solid #dde3e8",
                color: colors.darkerblue,
                borderRadius: 4
            },
            disabled: disabledStyle,
            selectMenu: {
                // border radius here doesn't have the desired effect, so the background of this element is transparent,
                // since it clips off the "inward rounded" corners of the contained element if it has a background color other than transp.
                fontSize: 12,
                borderRadius: 4,
                backgroundColor: "transparent !important"
            }
        },
        MuiLinearProgress: {
            root: {
                borderRadius: 10,
                "&>div": {
                    borderRadius: 10,
                },
                "&.red div": {
                    backgroundImage: "linear-gradient(91deg, #f7548f, #fa337b)",
                },
                "&.green div": {
                    backgroundImage: "linear-gradient(to left, #1de9b6, #1dc4e9)",
                }
            },
            colorPrimary: {
                backgroundColor:"#eaeaea",
            }
        },
        MuiButton: {
            root: {
                fontWeight: 600,
                fontSize: 12,
                textTransform: "none",
                //list actionmenu css override
                '&.action-menu': {
                    padding: '0px',
                    minWidth: 'unset',
                    width: '100%',
                    borderRadius: '0px',
                    /* opacity: '0.6', */
                    '&:hover': {
                        backgroundColor: 'transparent',
                        /* opacity: '1' */
                    }
                },
                '&.list-menu': {
                    fontWeight: 'unset',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: 'white',
                    height: '100%',
                    color: "#dde3e8",
                    borderRadius: '2px',
                    borderColor: "#dde3e8",
                    minWidth: '160px',
                    /* opacity: '0.6', */
                    padding: '8px 12px',
                    '&:hover': {
                       /*  opacity: '1' */
                    }
                }
            },
            sizeLarge: {
                fontSize,
            },
            containedPrimary: {
                color: "white",
            },
            text: {


            },
            outlined: {
                '&.context-menu': {

                },
                /*(invoice) list menu button css override*/
                '&.list-menu': {
                    fontWeight: 'unset',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: 'white',
                    height: '100%',
                    "color": "#83838b",
                    borderRadius: '2px',
                    borderColor: "#dde3e8",
                    minWidth: '160px',
                    /* opacity: '0.6' */
                    padding: '8px 12px',
                    /* '&:hover': {
                        opacity: '1'
                    }  */
                },
                "&.red": {
                    "color": "white",
                    "background": colors.salmon
                },
                "&.white": {
                    "color": colors.darkgrey,
                    "background": colors.white6
                },
            },
            contained: {
                "&.green": {
                    "color": "white",
                    "background": colors.green,
                },
                "&.red": {
                    "color": "white",
                    "background": colors.salmon,
                },
                "&.darkred": {
                    "color": "white",
                    "background": colors.darkred,
                },
                "&.blue": {
                    "color": "white",
                    "background": colors.primary,
                },
                "&.white": {
                    "color": colors.darkgrey,
                    "background": colors.white6
                },
                boxShadow: "none",
            },

        },
        //* Kaataa settingsin
        MuiListItem: {
            'button': {
                '&:hover': {
                    backgroundColor: 'rgba(44, 159, 247, 0.08)',
                },
            },
        },
        MuiTabs: {
            root: {
                color: colors.darkblue,
                "&.simple button[aria-selected=true]": {
                    backgroundColor: colors.primary,
                    borderRadius: 25,
                    color: "white",
                },
                "&.simple div[role=tablist]>span": {
                    display:"none"
                },
                "&.simple button": {
                    minWidth: "initial",
                    fontSize: "12px !important", /* default theme mediaqueries f*ck things up */
                    minHeight: 32,
                },
                "&.simple": {
                    minHeight: 32,
                },
                "& button": {
                    minWidth: "initial",
                    fontSize: "12px !important", /* default theme mediaqueries f*ck things up */
                },
                "& button span": {
                    span: "6px 14px",
                },

                "&.details div[role=tablist]>span": {
                    display:"none"
                },
                "&.details button": {
                    flex: 1,
                    borderBottom: '1px solid #dde3e8',
                    backgroundColor: '#ededed',
                    color: '#9b9b9b',
                    borderRight: '1px solid #dde3e8',
                },
                "&.details button:last-of-type": {
                    borderRight: 0,
                },
                "&.details button[aria-selected=true]": {
                    backgroundColor: 'white',
                    borderBottom: 0,
                    color: '#34495e',
                },
                "&.details button:last-child": {
                    borderTopRightRadius: 5,
                },
                "&.details button:first-child": {
                    borderTopLeftRadius: 5,
                },
            }
        },
        MuiTab: {
            root: {
                fontSize: "12px !important", /* default theme mediaqueries f*ck things up */
                fontWeight: "600",
                textTransform: "none",
                opacity:"1 !important",
            },
            selected: {
                color: colors.primary,
            },
            disabled: {
                opacity:"0.4 !important",
            }
        },
        MuiSvgIcon: {
            root: {
                color: "#7c86a2",
                verticalAlign: "middle",
            },
            colorDisabled: {
                color: "#a2abc5",
            }
        },
        MuiAvatar: {
            root: {
                color: "white",
                fontSize: 11,
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 600,
                textAlign: "center",
            },
        },
        MuiCheckbox: {
            colorPrimary: {
                "& svg": {
                    color: colors.primary,
                }
            },
        },
        MuiDialog: {
            paperWidthXs: {
                overflow: "unset"
            }
        },
        MuiDialogContent: {
            root: {
                "&.invoiceDialogContent": {
                    overflow: "unset"
                }
            }
        },
        MuiFormControl: {
            root: {
                "&.fullWidth": {
                    width: "100%",
                    textAlign: "right",
                    marginBottom: "12px"
                },

                "&.date-range": {
                    float: "left",
                    margin: "0px",
                    marginRight: "8px"
                },
                "&disabled": disabledStyle,
            },
        },
        MuiOutlinedInput: {
            disabled: disabledStyle,
        },
        MuiDialogActions: {
            root: {
                margin: "12px 20px",
                justifyContent: "space-around"
            }
        },
        MuiModal: {
            root: {
                zIndex: 60000,
            }
        }
    },
    props: {
        MuiFormControl: {
            margin: "dense",
        },
        MuiButton: {
            variant: "contained",
        }
    }
});

