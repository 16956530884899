import React from 'react';

/* local components */
import List from './../list/List';
import PropsOnlyListRow from './../list/PropsOnlyListRow';
import ListCell from './../list/ListCell';
import DateCell from './../list/cells/DateCell';
import AutoCompleteCell from './../list/cells/AutoCompleteCell';
import TextInputCell from './../list/cells/TextInputCell';

import OutlinedField from './../general/OutlinedField';
import DoubleDateOutlinedField from './../general/DoubleDateOutlinedField';
import ContextMenu from './../general/ContextMenu';

import AccountDialog from './AccountDialog';
import UsersDialog from './UsersDialog';
import AddonConfirmationDialog from './AddonConfirmationDialog';

/* mui */
import { MenuItem, Button, Drawer, Divider, IconButton, Switch } from '@material-ui/core';
import { AccessibleForward, MoreHoriz, Refresh, ChevronLeft } from '@material-ui/icons';

/* dates */
import { format } from "date-fns";

/* data backend */
import DataHandler from './../general/DataHandler';

/* css */
import './AccountList.css';

const VERSIONS = {
    "1": {id: "1", name: "Free CRM"},
    "2": {id: "2", name: "Sales CRM"},
    "3": {id: "3", name: "Project Management"},
    "4": {id: "4", name: "Erp"},
    "10": {id: "10", name: "Entry Free"},
    "11": {id: "11", name: "Entry Growth"},
    "12": {id: "12", name: "Entry Business"},
    "0": {id: "0", name: "All"},
};
const LOCKED = [
    {id: "-1", label: "Active", name: "Active"},
    {id: "1", label: "Locked", name: "Locked"},
    {id: "0", label: "All", name: "All"},
];

const _parseJwtForPermissons = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const payload = JSON.parse(jsonPayload);

    return payload ? payload.permissions : {};
};

const displayableAddons = [
    { 
        setting_name: "quoterow_partial_invoicing_for_products", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "Navitec"
    },
    { 
        setting_name: "custom_project_id", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "Navitec"
    },
    { 
        setting_name: "projects", 
        isCompanySpecific: false, 
        hasLimit: true, 
        isEditable: true, 
        description: ""
    },
    { 
        setting_name: "expenses", 
        isCompanySpecific: false, 
        hasLimit: true, 
        isEditable: true, 
        description: ""
    },
    { 
        setting_name: "sales_quotes", 
        isCompanySpecific: false, 
        hasLimit: true, 
        isEditable: true, 
        description: ""
    },
    { 
        setting_name: "invoices_project_limit", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "If enabled, only one project can be added to sales invoice and info about that project will be added to invoice when sending to Talenom"
    },
    { 
        setting_name: "notifications", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "additionaly you should enable it in run.sh and cron.d file of the notification servic"
    },
    { 
        setting_name: "invoicing_notifications", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "If enabled allows the creation of invoicing notifications."
    },
    { 
        setting_name: "custom_customer_id", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: ""
    },
    { 
        setting_name: "allow_consumer_customers", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "User can define what customer type is for consumers. Used now only with Ropo Capital where if invoiced customer has the consumer customer type, invoice will be sent as a consumer invoice to Ropo Capital."
    },
    { 
        setting_name: "emce_invoicing", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "Disables the option for grouping hour entries in sales invoice and groups them always by resourcing tasks. Allows only one project to be added to sales invoice. Uses users as dimension in accounting dropdown."
    },
    { 
        setting_name: "venda", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "Forces integration id to be mandatory in product register. Unlocks venda settings in integration settings. Accounting products must be used for all invoice rows for Venda compliant Finvoice 3.0 export. Accounting products can be defined in the aforementioned settings. Requires entry to integrations table, api_name being the same as settings_extras value."
    },
    { 
        setting_name: "unified_corporate_invoicing", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "Uses company_address_name for invoices company name. Unifies Invoice Nr. Skips Bank account validation"
    },
    { 
        setting_name: "open_api", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "This enables UI to actually activate the API and to generate the authorization token for the requests. At first this will only be enabled for Raval"
    },
    { 
        setting_name: "mass_editing", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "Originally for HR-Tilipalvelut. Made into an add-on because it’s very important to push it to production even when it’s broken, and we need to be able to hide it."
    },
    { 
        setting_name: "invoice_currency",
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "print invoices with selected currency"
    },
    { 
        setting_name: "contact_owner", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: ""
    },
    { 
        setting_name: "mitta_pr", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "Billable toggle in the workhourentry dialog, as of now only one customer can see this toggle."
    },
    { 
        setting_name: "project_notifications", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "Also contains the option to receive notifications from projects imported from hubspot if hubspot is active"
    },
    { 
        setting_name: "multicompany_hoursreport", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "AX suunnittelulla Ominaisuus on tarkoitus ottaa lähiaikoina käyttöön niin tämä addon poistuu"
    },
    { 
        setting_name: "show_invoice_summary_rows_report", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "positive value enables invoice summary with rows in invoicing reports"
    },
    { 
        setting_name: "dimensions", 
        isCompanySpecific: true, 
        hasLimit: false, 
        isEditable: true, 
        description: "Räätäl’d for Azets for now, in the future active for everyone by default"
    },
    { 
        setting_name: "disable_hours_overlap", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "Blocks from creating / updating hour entries to overlap."
    },
    { 
        setting_name: "refund_material", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: ""
    },
    { 
        setting_name: "corporate_automatic_project_numbering", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "disables manual project numbering and automatically numbers projects."
    },
    { 
        setting_name: "custom_project_dimension_header", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "When fetching bills from integration (now only used in Netvisor) get projects from row dimensions. If project is not found, don't save bill."
    },
    //manual picks from test db
    { 
        setting_name: "default_pricelist", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "?"
    },
    { 
        setting_name: "heeros", 
        isCompanySpecific: true, 
        hasLimit: false, 
        isEditable: true, 
        description: "?"
    },
    { 
        setting_name: "show_heeros", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "?"
    },
    { 
        setting_name: "purchaseorder_notifications", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "?"
    },
    { 
        setting_name: "exportButton", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "?"
    },
    { 
        setting_name: "hide_billing_rate_by_user_report", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "?"
    },
    { 
        setting_name: "hours_insight_hide_users", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "?"
    },
    { 
        setting_name: "resourcing_grid_olkd", 
        isCompanySpecific: false, 
        hasLimit: false, 
        isEditable: true, 
        description: "?"
    },

];

const addonColumns = [
    { name: "context", header: "", columnHeaderType: "roundButton", width: 40, resizeable: false, moveable: false, hideable: false },
    { name: "activated", header: "Active", width: 80 },
    { name: "setting_name", header: "Name", width: 300 },
    { name: "description", header: "Description", width: 500 },
    { name: "addon_limit", header: "Limit", width: 80 },
    { name: "company", header: "Company", width: 200 },
    { name: "expires", header: "Expires at", width: 113 },
];

const Slider = (props) => {

    const columns = props.companies && props.companies.length > 1 ? addonColumns : addonColumns.filter(a => a.name != 'companies');
    const customerName = props.customer ? props.customer.company : undefined;

    return (
        <div className="slider-addons-wrapper"  >
            <Drawer
                className="slider-addons"
                variant="persistent"
                anchor="right"
                open={props.open} >
                <div className="slider-addons-header">
                    <IconButton onClick={props.handleSliderClose}>
                        <ChevronLeft />
                    </IconButton>
                    <strong>{`Addons for ${customerName}`}</strong>
                    <Button
                        color="primary"
                        disabled={props.addonsUpdate.length < 1}
                        onClick={() => props.save()} >
                        Save changes
                    </Button>
                </div>
                    <Divider />
                    <List
                        key={props.sliderListKey}    
                        fluid 
                        height="fitRemaining" 
                        data={props.addons} 
                        columns={columns}
                        rowKey="id"
                        listRowType={AddonListRow}
                        rowProps={props.rowProps}
                        noStateData={true}
                        sharedData={{companies: props.companies, newAddons: props.newAddons}}
                        roundbutton={props.addEmptyAddon}
                    />
          </Drawer>
        </div>
    );
}

class AddonListRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {});
    }

    defineCells() {
        const { columnOrder, columnWidthMap, sharedData, rowProps } = this.props;

        const { data } = this.props;

        const updateAddons = (changedField, value) => rowProps.updateAddons(data, changedField, value);
        const addAddon = (defaultData) => rowProps.addAddon({...defaultData, id: data.id});

        const { addons } = rowProps.userPermissions;

        const isEditable = (field) => {
            let editable = addons > 0 ? data.isEditable : false;
            if (!editable)
                return false;
            if (field == 'addon_limit')
                return data.hasLimit;
            if (field == 'company')
                return data.isCompanySpecific;
            return editable;
        }

        const cells = {
            "context": (
                <ListCell
                    width={columnWidthMap.context}
                    editable={false} />
            ),
            "activated": (
                <ListCell
                    editable={isEditable('activated')}
                    width={columnWidthMap.activated}
                    onlyDisplay >
                    <div className="costs-option">
                        <Switch
                            name="activated"
                            color="primary"
                            disableRipple={true}
                            onChange={(e) => this.setData("activated", data.activated > 0 ? 0 : 1, updateAddons('activated', data.activated > 0 ? 0 : 1))}
                            checked={data.activated*1}
                            disabled={!isEditable('activated')}
                        />
                    </div>
                </ListCell>
            ),
            "setting_name": (
                data.id > 0 ?
                <ListCell
                    width={columnWidthMap.setting_name}
                    value={data.setting_name}
                    editable={false} />
                :
                <AutoCompleteCell
                    autoCompleteData={sharedData.newAddons}
                    autoCompleteLabelKey="setting_name"
                    listCellProps={{
                        
                    }}
                    name="setting_name"
                    value={data.setting_name || false}
                    width={columnWidthMap.setting_name}
                    editable={true}
                    onEdited={option => this.setData({...option, id: data.id}, false, addAddon(option))} />
            ),
            "description": (
                <ListCell
                    width={columnWidthMap.description}
                    value={data.description}
                    showTooltipForOverflownText
                    editable={false} />
            ),
            "addon_limit": (
                <TextInputCell   
                    width={columnWidthMap.addon_limit}
                    name="addon_limit"
                    textAlign="right"
                    value={(isEditable('addon_limit') || data.addon_limit > 0) ? data.addon_limit : 'n/a'}
                    editable={isEditable('addon_limit')}
                    onEdited={(value) => updateAddons("addon_limit", value)} />
            ),            
            "company": (
                data.companies_id > 0 ? <AutoCompleteCell
                    autoCompleteData={sharedData.companies}
                    listCellProps={{
                        noInitFocus: true
                    }}
                    name="company"
                    value={data.companies_id > 0 ? data.companies_id : false}
                    width={columnWidthMap.company}
                    editable={isEditable('company')}
                    onEdited={option => this.setData("company", option.id, updateAddons("company", option.id))} /> : 
                <ListCell
                    width={columnWidthMap.company}
                    value="n/a"
                    textAlign="right"
                    editable={false} />
            ),
            "expires": (
                <DateCell
                    width={columnWidthMap.expires}
                    offsetCalendar={true}
                    closeOnComplete={false}
                    closeCalendarOnComplete={true}
                    usePopper={true}
                    popperBottom={true}
                    value={data.expires}
                    editable={isEditable('expires')}
                    listCellProps={{
                        inEditMode: !data.expires && data.expires !== null
                    }}
                    onEdited={date => {
                        this.setData("expires", date, updateAddons("expires", date));
                    }} />
            )
        };

        return cells;
    }

}

class AccountRow extends PropsOnlyListRow {
    constructor(props, subtypeState = {}, rowTypeConfig = {}) {
        super(props, subtypeState, rowTypeConfig);
    }
    cellEdited = (name, value) => {
        if(this.props.rowProps.refreshedRows.indexOf(this.props.data.folder) == -1) {
            alert("Refresh account before editing");
            return;
        }
        this.setDataAndUpdate(name, value);
    }
    render() {
        const { columnOrder, columnWidthMap, sharedData, rowProps } = this.props;

        const { login, addons, list_users } = rowProps.userPermissions;

        const { data } = this.props;

        const props = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": `cell ${prop}`, style: {width: columnWidthMap[prop] + 'px', flex: columnWidthMap[prop] + " 1 0px" }} }
        });
        const cellProps = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, listCellProps: {"className": prop}, width: columnWidthMap[prop], name: prop, value: data[prop]}}
        });

        const cells = {
            "context": ( 
                <ContextMenu label={<MoreHoriz />} {...cellProps.context} noExpandIcon>
                    {login > 0 && <MenuItem onClick={() => rowProps.loginAs(data)}>
                        <AccessibleForward /> Login As
                    </MenuItem>}
                    {login < 1 && list_users > 0 && <MenuItem onClick={() => rowProps.showUsers(data)}>
                        <AccessibleForward /> Show users
                    </MenuItem>}                    
                    {/*
                    <MenuItem onClick={() => rowProps.openAddonSlider(data)}>
                        <AccessibleForward /> {addons > 0 ? 'Manage addons' : 'Check addons'}
                    </MenuItem>
                    */}
                    <MenuItem onClick={() => {rowProps.refresh(data); this.setState({isRefreshed: true})}}>
                        <Refresh /> Refresh
                    </MenuItem>
                </ContextMenu>
            ),
            "id": (
                <ListCell
                    editable={false}
                    width={columnWidthMap.id}
                    name="id"
                    value={data.id} />
            ),
            "folder": (
                  <ListCell
                    {...cellProps.folder}
                    editable={false} />
            ),
            "company": (
                 <ListCell
                    {...cellProps.company}
                    editable={false} />
            ),
            "version": (
                <AutoCompleteCell
                    width={columnWidthMap.version}
                    autoCompleteData={Object.values(VERSIONS).filter(e => e.id !== "0")}
                    value={data.version}
                    onEdited={option => this.cellEdited("version", option.id)} />
            ),
            "addons": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.addons}
                        name="addons"
                        showTooltipForOverflownText
                        value={data.addons} />
            ),           
            "country": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.country}
                        name="country"
                        value={data.country} />
            ),
            "email": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.email}
                        name="email"
                        value={data.email} />
            ),
            "lastname": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.lastname}
                        name="lastname"
                        value={data.lastname} />
            ),
            "firstname": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.firstname}
                        name="firstname"
                        value={data.firstname} />
            ),
            "created_at": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.created_at}
                        name="created_at"
                        value={data.created_at} />
            ),
            "onboarding_ends": (
                <DateCell
                    width={columnWidthMap.onboarding_ends}
                    value={new Date(data.onboarding_ends)}
                    onEdited={(n, date) => this.cellEdited("onboarding_ends", date)} />
            ),
            "locked": (
                <AutoCompleteCell
                    width={columnWidthMap.locked}
                    autoCompleteData={LOCKED.filter(e => e.id !== "0")}
                    value={data.locked}
                    onEdited={option => this.cellEdited("locked", option.id)}/>
            ),
            "locked_date": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.locked_date}
                        name="locked_date"
                        value={data.locked_date} />
            ),
            "userlimit": (
                <TextInputCell
                        width={columnWidthMap.userlimit}
                        name="userlimit"
                        value={data.userlimit}
                        onEdited={this.cellEdited} />
            ),
            "active_users": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.active_users}
                        name="active_users"
                        value={data.active_users} />
            ),
            "disabled_users": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.disabled_users}
                        name="disabled_users"
                        value={data.disabled_users} />
            ),
            "is_trial": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.is_trial}
                        name="active_users"
                        value={data.is_trial > 0 ? "Yes" : "No"} />
            ),
            "attachments_max": (
                <TextInputCell 
                        width={columnWidthMap.attachments_max}
                        name="attachments_max"
                        value={data.attachments_max}
                        onEdited={this.cellEdited} />
            ),
            "rc_version": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.rc_version}
                        name="rc_version"
                        value={data.rc_version} />
            ),
            "stripe_customer_id": (
                <ListCell
                        editable={false}
                        width={columnWidthMap.stripe_customer_id}
                        name="stripe_customer_id"
                        value={data.stripe_customer_id} />
            )


        };

        return (
            <div className="row listElement" style={{
                height: "44px",
                lineHeight: "44px",
                display: "flex"
            }}>
                {columnOrder.map(columnName =>{
                    return cells[columnName]
                    }
                )}
            </div>
        );
    }

}


class AccountList extends React.Component {
    state = {
        page: 1,
        pages: 1,
        perpage: 30,
        locked: "-1",
        version: "0",
        accounts: [],
        dialog: null,
        usersDialog: null,
        created_from: null,
        created_to: null,
        freetext: "",
        refreshAll: false,
        refreshedRows: [],
        updated: "",
        customerCount: 0,
        addonsUpdate: [],
        refreshAddons: false
    }
    
    constructor(props) {
        super(props);
        this.freetext = React.createRef();
        [   "updateComponentData",
            "toggleDialog",
            "toggleUsersDialog",
            "onSortRows",
            "filterChanged",
            "onDateChange",
            "refreshAccount",
            "updateAccount",
            "refreshAll",
        ].forEach(e => this[e] = this[e].bind(this));

        this.list = React.createRef();

    }
    
    componentWillMount() {
        this.updateComponentData();
    }
    
    updateComponentData() {
        const {page, locked, version, created_from, created_to, freetext, perpage } = this.state;
        let data = {
            url: "accounts/" + page,
            sort: this.sortTerms,
            locked,
            version,
            created_from,
            created_to,
            freetext,
            perpage
        };
        
        DataHandler.get(data).done( e => 
            this.setState({accounts: e.page, pages: e.pages, updated: e.updated, customerCount: e.customer_count, refreshAll: false})
        );
    }
    toggleDialog (data) {
        const { dialog } = this.state;
        
        this.setState({dialog: dialog ? null : data});
    }
    toggleUsersDialog (data) {
        const { usersDialog } = this.state;
        
        this.setState({usersDialog: usersDialog ? null : data});
    }    
    toggleAddonSlider = (data) => {
        DataHandler.get({url: `accounts/${data.id}/addons`}).done( e => {
            let allAddons = [...displayableAddons];
            const existingAddons = e.addons.reduce((acc, ad) => {
                const i = allAddons.findIndex(d => d.setting_name == ad.setting_name);
                if (i >= 0) {
                    const a = {...allAddons[i], ...ad};
                    if (!allAddons[i].isCompanySpecific)
                        allAddons.splice(i, 1);
                    return [...acc, a];
                }
                return acc;
            }, []);
            allAddons = allAddons.map(a => ({...a, activated: '0', addon_limit: '0', companies_id: a.isCompanySpecific ? '1' : '0', id: a.setting_name}));
            this.setState({addonSlider: true, addons: existingAddons, newAddons: allAddons, selectedCustomer: data, currentCompanies: e.companies})
        });
    }

    onSortRows (colName, asc) {
        this.sortTerms = { name: colName, asc: asc };
        this.updateComponentData();
    }
    filterChanged (e) {
        const {name, value} = e.target;
        this.setState({[name]: value}, this.updateComponentData);
    }
    onDateChange (dateRange) {
        this.setState({
            created_from: dateRange.startDate.getTime() > 0 ? format(dateRange.startDate, 'YYYY-MM-DD') : null,
            created_to: dateRange.endDate.getTime() > 0 ? format(dateRange.endDate, 'YYYY-MM-DD') : null,
        }, this.updateComponentData);
    }
    refreshAccount (account) {
        let { refreshedRows } = this.state; 
        DataHandler.put({url: `accounts/${account.id}/refresh`}).done(() => {refreshedRows.push(account.folder)
            this.setState({refreshedRows: refreshedRows}, () => {
                this.updateComponentData();
            });
        });
    }
    refreshAll (account) {
        let { accounts } = this.state; 
        this.setState({refreshAll: true});
        DataHandler.put({url: `accounts/refreshAll`}).done(this.updateComponentData).always(e => this.setState({refreshAll: false}));
    }
    updateAccount (account) {
        DataHandler.put({url: `accounts/${account.id}`}, account).done(this.updateComponentData);
    }

    setAddons = (data, changedField = undefined, value = undefined) => {
        let previousChange = {};
        if (changedField && value) {
            previousChange = this.state.addonsUpdate.find(f => f.id == data.id);
            data['changed'] = previousChange ? [...previousChange.changed, changedField] : [changedField];
            data[changedField] = value;            
        } else {
            data['new_addon'] = true;
        }

        this.setState({addonsUpdate: [...this.state.addonsUpdate.filter(a => a.id != data.id), {...previousChange, ...data}]});
    }

    callAddonConfirmationDialog = () => {
        this.setState({addonConfirmation: true});
    }

    saveAddons = () => {
        const {selectedCustomer, addonsUpdate} = this.state;
        DataHandler.put({url: `accounts/${selectedCustomer.id}/addons`, addonsUpdate}).done( e => 
            this.setState({addonConfirmation: false, addonSlider: false, addonsUpdate: [], selectedCustomer: []})
        );
    } 

    addEmptyAddonRow = () => {
        const lastNewId = this.state.addons.reduce((acc, a) => a.id < acc ? a.id : acc, 0)-1;
        const newAddonRow = {
            id: lastNewId,
            setting_name: undefined,
        };
        let addons = [...this.state.addons];
        addons.unshift(newAddonRow);
        this.setState({addons: addons});
    }

    addNewAddonRow = (data) => {
        let addons = [...this.state.addons];
        const i = addons.findIndex(a => a.id == data.id);
        addons[i] = {...data};
        this.setState({addons: addons}, () => this.setAddons({...data, changed: []}));
    }    

    render() {
        const { accounts, dialog, usersDialog, page, pages, locked, version, created_from, created_to, freetext, refreshAll, updated, addonSlider, addons, newAddons, addonsUpdate, currentCompanies, selectedCustomer } = this.state;
        const columns = [
            { name: "context", header: "", width: 60 },
            { name: "id", header: "Id", width: 113 },
            { name: "folder", header: "Folder", width: 113 },
            { name: "company", header: "Company", width: 113 },
            { name: "version", header: "Version", width: 113 },
            { name: "addons", header: "Addons", width: 113 },
            { name: "locked", header: "Locked", width: 113 },
            { name: "is_trial", header: "Is trial", width: 113 },
            { name: "created_at", header: "Created", width: 113 },
            { name: "onboarding_ends", header: "Onboarding ends", width: 113 },
            { name: "locked_date", header: "Locked date", width: 113 },
            { name: "userlimit", header: "Sold licenses", width: 113 },
            { name: "active_users", header: "Active users", width: 113 },
            { name: "disabled_users", header: "Disabled users", width: 113 },
            { name: "country", header: "Country", width: 113 },
            { name: "email", header: "Email", width: 113 },
            { name: "lastname", header: "Last name", width: 113 },
            { name: "firstname", header: "First name", width: 113 },
            { name: "attachments_max", header: "Attachment limit", width: 113 },
            { name: "rc_version", header: "RC", width: 113 },
            { name: "stripe_customer_id", header: "Stripe ID", width: 113 },
            
            
        ];

        const userPermissions = _parseJwtForPermissons(sessionStorage.adminToken);
        
        const rowProps = {
            loginAs: this.toggleDialog,
            showUsers: this.toggleUsersDialog,
            openAddonSlider: this.toggleAddonSlider,
            refresh: this.refreshAccount,
            refreshedRows: this.state.refreshedRows,
            userPermissions: userPermissions,
            onUpdate: (data) => {
                this.updateAccount(data);
            },
            
        }

        const sliderProps = {
            open: addonSlider,
            handleSliderClose: () => this.setState({addonSlider: false, addonsUpdate: [], refreshAddons: !this.state.refreshAddons}),
            addons: addons,
            newAddons: newAddons,
            companies: currentCompanies,
            customer: selectedCustomer,
            save: this.callAddonConfirmationDialog,
            sliderListKey: + this.state.refreshAddons,
            addonsUpdate: addonsUpdate,
            addEmptyAddon: this.addEmptyAddonRow,
            rowProps: {
                userPermissions: userPermissions,
                updateAddons: this.setAddons,
                addAddon: this.addNewAddonRow
            }
        };

        const addonConfirmationProps = {
            onDialogClose: () => this.setState({addonConfirmation: false, addonsUpdate: [], refreshAddons: !this.state.refreshAddons, addons: addons.filter(t => t.id > 0)}),
            onDialogSave: this.saveAddons,
            data: addonsUpdate,
            customer: selectedCustomer,
            companies: currentCompanies,
        };
        
        return (
            <div id="account-list">
                <div className="filters">
                    <OutlinedField select label="Filter by active status" value={locked} name="locked" onChange={this.filterChanged}>
                    {LOCKED.map(row =>
                        <MenuItem key={row.id} value={row.id}>{row.label}</MenuItem>
                    )}
                    </OutlinedField>
                    <OutlinedField select label="Filter by version" value={version} name="version" onChange={this.filterChanged}>
                    {Object.values(VERSIONS).map(row =>
                        <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                    )}
                    </OutlinedField>
                    <DoubleDateOutlinedField
                        rootClass="date-range"
                        range={{startDate: new Date(created_from), endDate: new Date(created_to)}}
                        label="Filter by created date"
                        dateFormat={"YYYY-MM-DD"}
                        onChange={this.onDateChange} />
                    <OutlinedField label="Search all fields" value={freetext} name="freetext" onChange={this.filterChanged} ref={this.freetext} onKeyUp={e => e.keyCode === 13 && this.freetext.current.blur()}  />
                    <div className='refresh'>
                        <OutlinedField label="Last refresh" value={updated} disabled />
                        <Button size='large' color='primary' disabled={refreshAll} onClick={this.refreshAll}>
                            <Refresh /> &nbsp; Refresh all &nbsp; <span style={{fontSize: 10}}>(don't spam)</span>
                        </Button>
                    </div>
                </div>
                    <List         
                        fluid 
                        height="fitRemaining" 
                        ref={this.list}
                        data={accounts} 
                        columns={columns}
                        rowKey="id"
                        listRowType={AccountRow}
                        rowProps={rowProps}
                        pageCount={pages}
                        userListSettingsKey="tabAccounts"
                        onSortRows={this.onSortRows}
                        showPageSelector
                        noStateData={true}
                        onPageChange={page => this.filterChanged({target:{name: "page", value: page}}) }
                        totalCount={this.state.customerCount}
                        perpage={this.state.perpage}
                        onPerPageChange={perpage => {
                            this.list.current.startPageChangeAnimation();
                            this.setState({ perpage: perpage }, () => {
                                this.updateComponentData();
                                this.list.current.endPageChangeAnimation();
                            });
                        }}/>
                        
                {dialog && <AccountDialog account={dialog} toggleDialog={this.toggleDialog} />}
                {usersDialog && <UsersDialog account={usersDialog} toggleDialog={this.toggleUsersDialog} />}

                {this.state.addonConfirmation && <AddonConfirmationDialog {...addonConfirmationProps} />}

                <Slider {...sliderProps}/>
            </div>           
        );
        
    }
}

export default AccountList;