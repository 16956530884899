/* jquery */
import $ from 'jquery';
 
const useBackEnd = process.env.REACT_APP_USE_BACKEND > 0 || process.env.NODE_ENV === 'production';

const { adminToken } = sessionStorage;

/* for odin only, basic auth f*ck things up */
const headers = {[window.location.href.indexOf('odin')  > -1 ? 'Authorization-taimer' : 'Authorization']: `Bearer ${adminToken}`};

const DataHandler = {
    url: (params) => {
        let url = ""; 
        if (!useBackEnd)
            url = "/json/" + (params.url ? params.url.replace(/\//g, '_') + ".json" : `/${params.module}_${params.action}.json`);
        else if (params.url)
        {
            let { url: params_url, ...p } = params;
            url = (window.location.href.indexOf("odin") > -1 ? "api/" : "/api/") + params_url + (p ? `?&${$.param(p)}` : "");
        }
        else 
            url = "faceless.php?" + $.param(params);

        return url;
    },
    request: (method, params, data) => {
        return $.ajax({
            url: typeof params === "string" ? params : DataHandler.url(params),
            data,
            headers,  
            method: method,
        });
    },
    get: (params) => {
        return DataHandler.request("GET", params);
    },
    post: (params, data) => {
        return useBackEnd ? DataHandler.request("POST", params, data) : DataHandler.request("GET", params);
    },
    put: (params, data) => {
        return useBackEnd ? DataHandler.request("PUT", params, data) : DataHandler.request("GET", params);
    },
    "delete": (params, data) => {
        return useBackEnd ? DataHandler.request("DELETE", params, data) : DataHandler.request("GET", params);
    }, 
    file: (params, file, success, complete) => {
        if (useBackEnd) {
            let data = file;

            if (!(data instanceof FormData))
            {
                data = new FormData();
                data.append("attachment", file);
            }
            
            return $.ajax({
                url: DataHandler.url(params),
                type: 'POST',
                data,
                dataType: "json",
                processData: false,
                contentType: false,
                headers,   
                success: success,
                complete: complete
            });
        } else {
            return $.get( DataHandler.url(params) );
        }
    },
    getArrayBuffer: (params) => {

        return $.ajax({
            url: DataHandler.url(params),
            type: 'GET',
            processData: false,
            headers,
            xhrFields: {responseType: 'arraybuffer'}
        });

    }    
};

export default DataHandler;