import React from 'react';
import PropTypes from "prop-types";

/* material ui */
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

/* validator */
import validator from 'validator';

export const validators = {
    empty: e => !validator.isEmpty(e, { ignore_whitespace: true }),
    numeric: e => !e.length || validator.isNumeric(e),
    email: e => !e.length || validator.isEmail(e),
};

class OutlinedField extends React.Component {
    static defaultProps = {
		adornmentPos: "start",
        shrinkLabel: undefined,
        className: ""
    };

    static getDerivedStateFromProps(props, state) {
        if (props.value === state.initialValue)
            return state;

        return {...state, initialValue: props.value, value: props.value === undefined ? "" : props.value};
    }
    state = {
        "initialValue": undefined,
        "value": "",
        "valid": true,
    };
    constructor(props) {
        super(props);
        ["onKeyUp", "onChange", "onFocus", "isValid"].forEach(e => this[e] = this[e].bind(this));
        this.ref = React.createRef();
        this.inputRef = props.inputRef || React.createRef();
    }
    focus = () => {
        this.ref.current && this.ref.current.focus();
    }
    blur = () => {
        this.inputRef.current && this.inputRef.current.blur();
    }
    onKeyUp (evt) {
        const { value } = evt.target;
        this.setState({value});
    }
    isValid (value) {
        value = value !== undefined ? value : this.state.value;
        const { validation } = this.props;
        const valid = !validation || validation.find(e => !validators[e](value)) === undefined;

        this.setState({valid});

        return valid;
    };
    onChange (evt) {
        const { validation, onChange } = this.props;
        const { value } = evt.target;
        const valid = this.isValid(value);
        valid && onChange && onChange(evt);
    }
    onFocus (evt) {}
    render() {
        const { valid } = this.state;
        const { error, theme, adornment, adornmentPos, InputProps, InputLabelProps, validation, useOnChange, shrinkLabel, ...rest} = this.props;

        let children = this.props.children ? this.props.children : undefined;
        if(children) {
            children = React.Children.toArray(children);
        }

        const adornmentPosName = adornmentPos === "start" ? "startAdornment" : "endAdornment";

        if (adornment)
            rest.InputProps = {...InputProps, [adornmentPosName]: (<InputAdornment position={adornmentPos}>{adornment}</InputAdornment>)};
        else
            rest.InputProps = InputProps;

        if (rest.type === 'date')
            rest.InputLabelProps = Object.assign(InputLabelProps || {}, { shrink: true });
        else
            rest.InputLabelProps = InputLabelProps;

        if (!rest.variant)
            rest.variant = "filled";
        if (rest.select || useOnChange)
            rest.onChange = this.onChange;
        else {
            rest.onChange = this.onKeyUp;
            rest.onBlur = this.onChange;
        }

        if(rest.paperFix) {
            rest.inputProps = {
                onFocus: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }
            };
        }

        let className = this.props.className;

        if(shrinkLabel !== undefined)
            rest.InputLabelProps = Object.assign(rest.InputLabelProps || {}, { shrink: this.props.shrinkLabel });

        // If the TextField is of the select variety, pass .select class to the InputLabel to keep the
        // label from changing color on focus.
        // This is defined in TaimerTheme.js -> MuiFormLabel->root->&.select.
        if(rest.select)
            rest.InputLabelProps = Object.assign(rest.InputLabelProps || {}, { className: (rest.InputLabelProps !== undefined ? rest.InputLabelProps.className : "") + " select" });

        return (
            <TextField
                {...rest}
                className={className}
                value={this.state.value}
                error={error || (validation && !valid)}
                ref={this.ref}
                inputRef={this.inputRef}
                variant={rest.variant}>
                {children && children.map((child, index) => {
                    // MenuItems', that are inside a TextField select, onClick doesn't work, this is just a stupid hack to emulate that.
                    if(!rest.select || !child.hasOwnProperty("props") || !child.props.hasOwnProperty("onClick"))
                        return child;

                    // Child has onClick prop, we need to copy that callback.
                    return React.cloneElement(child, {
                        key: child.props.hasOwnProperty("key") ? child.props.key : index,
                        onMouseUp: child.props.onClick,
                        onTouchEnd: child.props.onClick
                    });
                })}
            </TextField>
        );
    }
};

OutlinedField.propTypes = {
    name: PropTypes.string.isRequired,
    validation: PropTypes.array,
    error: PropTypes.bool,
};

export default OutlinedField;