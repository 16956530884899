import React from 'react';
import DataHandler from "./general/DataHandler";

/* context */
import { SettingsContext } from './SettingsContext';

/* jquery */
import $ from 'jquery';
import _ from 'lodash';

import replace from "string-replace-to-array";

const DEV_LOCATIONS = [
    "taimer-stack:3000",
    "/odin",
    "localhost:3000",
];
const DEV_LOCATION = DEV_LOCATIONS.some(e => window.location.href.indexOf(e) !== -1) ? true : false;

let translations = {};

const storage = sessionStorage.taimerToken ? sessionStorage : localStorage;

if (!storage.taimerLang)
    storage.taimerLang = "en";

const prefix = window.location.href.indexOf("odin") > -1 ? "" : "/";

let lang = storage.taimerLang;

// in onboarding the language has to come from url parameters, hence this override possibility
const params = new URLSearchParams(window.location.search);
for (let [key, value] of params.entries()) {
    if (key == "lang") {
        lang = value;
        break;
    }
}

const version = process.env.REACT_APP_TAIMER_VERSION || "";

if (process.env.NODE_ENV !== "test") {
    if (DEV_LOCATION)
        $.ajax({
            url: prefix + 'react/api/translations/all_keys/' + lang,
            type: 'GET',
            async: false,
            cache: false,
            timeout: 30000,
            success: resp => translations = resp
        });
    else
        $.ajax({
            url: `${prefix}react/json/translations.${lang}.json?${version}`,
            type: 'GET',
            async: false,
            success: resp => translations = resp
        });
}

class TaimerComponent extends React.Component {
    static contextType = SettingsContext;

    constructor(props, context, namespace) {
        super(props, context);
        this.namespace = namespace;

        if (!translations[namespace])
            translations[namespace] = {};
        this._keys = translations[namespace];

        this.tr = this.tr.bind(this);
        this.getText = this.getText.bind(this);
        this.htmlTr = this.htmlTr.bind(this);
        this.lang = lang;
    }

    async componentDidMount() {
        if (!window.loadedComponents)
            window.loadedComponents = [];

        window.loadedComponents.push(this.namespace);
    }
    componentWillUnmount() {
        this._keys = null;
        window.loadedComponents = window.loadedComponents ? window.loadedComponents.filter(lc => lc !== this.namespace) : [];
    }

    getText(transl) {
        const keys = this._keys;

        let text = transl;

        if (DEV_LOCATION && keys !== undefined && (!keys || !keys[transl])) {
            if (keys) keys[transl] = transl;
            DataHandler.post({ url: 'translations/addkey' }, { namespace: this.namespace, key: transl });
            // return transl;
        } else if (keys[transl]) {
            text = keys[transl];
        }
        else if (process.env.NODE_ENV !== "test") {
            console.error("TRANSLATION KEYS NOT DEFINED!!!");
        }

        return text;
    }

    tr(transl, replacers = {}) {
        let text = this.getText(transl);

        _.forEach( replacers, (v, k) => text = text.replace(`\$\{${k}\}`, v) )

        return text;
    }

    htmlTr(transl, replacers = {}) {
        let text = this.getText(transl);

        function replaceHTML(match, key)
        {
            return replacers[key] ? replacers[key] : match;
        }

        return replace(text, /\$\{([a-zA-Z0-9]+)\}/g, replaceHTML);
    }
}

export default TaimerComponent;
