import React from 'react';

/* local components */
import OutlinedField from "./../general/OutlinedField";

/*material ui */
import Button from '@material-ui/core/Button';

/* css */
import './Login.css';

/* jquery */
import DataHandler from './../general/DataHandler';

class Login extends React.Component {
    state = {
        username: '',
        password: '',
        error: false,
        errorEmpty: false,
    }
    
    constructor(props) {
        super(props);
        this.getToken = this.getToken.bind(this);
    }
 
    getToken() {
        const username = this.username.value;
        const password = this.password.value;
        
        if(username.length <= 0) {
            this.setState({errorEmpty: true});
        } else {
            this.setState({errorEmpty: false});
        }

        DataHandler.post({url: "auth/login"}, {username, password})
            .fail(() => this.setState({error: true}))
            .done(this.setSession);
    };
    setSession = (response) => {
        this.setState({error: false});
        sessionStorage.setItem('adminToken', response.token);
        
        window.location.reload();
    }

    handleKeyPress = (event) => {
        if(event.key == 'Enter'){
            event.preventDefault();
            this.getToken();
        }   
    }

    render () {
        const { getToken, state } = this;
        return (
            <div id="taimer-login" onKeyPress={this.handleKeyPress} >
                <h2>Adminpanel</h2>
                <OutlinedField inputRef={el => this.username = el} label="Username" autoFocus={true} error={state.errorEmpty} />
                <OutlinedField inputRef={el => this.password = el} type="password" label="Password" error={state.error} />
                <Button color="primary" onClick={() => getToken()} size="large">Login</Button>
            </div>
        );
    };
}

export default Login;