import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Portal from '@material-ui/core/Portal';
import CalendarIcon from '@material-ui/icons/DateRange';
import { withStyles } from '@material-ui/core/styles';

import { DateRangePicker } from './react-date-range/src';
import OutlinedField from "./OutlinedField";
import moment from 'moment';
import { format, isValid } from 'date-fns';

import "./DoubleDateOutlinedField.css";

const styles = theme => ({
    disabled: theme.disabledStyle,
    fieldWrapper: {
        marginRight: "12px !important"
    },
    label: {
        fontSize: '14px',
        background: 'transparent !important',
        transform: 'translate(14px, 7px) scale(0.75) !important'
    },
    inputRoot: {
        border: 'none'
    },    
    input: {
        paddingTop: '22px',
        paddingBottom: '9px',
        paddingRight: '20px',
        color: '#83838b',
        fontSize: '12px'
    },
    svg: {
        position: 'absolute',
        top: '28%',
        left: '86%',
        height: '20px',
        width: '20px',
        cursor: 'pointer'      
    }
});

export function parseDateRange(value, dateFormat) {
    const [startDateTxt, endDateTxt] = value.split(' - ');

    const startDate = moment(startDateTxt, dateFormat);
    const endDate = moment(endDateTxt, dateFormat);

    return {startDate: startDate.toDate(), endDate: endDate.isValid() ? endDate.toDate() : startDate.toDate(), valid: startDate.isValid()};
}

export function formatDateRange(selection, dateFormat) {
    if (!isValid(selection.startDate))
        return '';

    return `${format(selection.startDate, dateFormat)} - ${format(selection.endDate, dateFormat)}`
}

class DoubleDateOutlinedField extends React.Component {

    constructor(props) {
        super(props);

    }    

    handleInputChange = event => {
        this.props.onInputChange && this.props.onInputChange(event.target.value);

        this.raiseChange(event.target.value, parseDateRange(event.target.value, this.props.dateFormat));
    };

    handleRangeChange(payload) {
        const range = payload[Object.keys(payload)[0]];

        this.props.onRangeChange && this.props.onRangeChange(range);

        this.raiseChange(formatDateRange(range, this.props.dateFormat), {...range, valid: true});
    }

    raiseChange = (input, range) => {
        //if (!onChange)
        //    return;

        const change = {
            ...range,
            input,
        }

        this.props.onChange && this.props.onChange(change);
    }

    fastEditHandler = (e) => {
        this.dateRangePicker.handleToggle();
    }

    onStartEdit = () => {
        this.dateRangePicker.handleToggle();
    }
    
    render() {

        const { classes, rootClass, value, range, dateFormat, fullWidth, error, minDate, disabled } = this.props;

        return (
            <FormControl className={`${classNames(classes.fieldWrapper, rootClass, disabled && classes.disabled)} date-range`} variant="outlined">
                <InputLabel
                    className={classNames(classes.label)}
                    shrink={true}
                    ref={ref => { this.labelRef = ReactDOM.findDOMNode(ref); }}
                    htmlFor="date-outlined" >

                    {this.props.label}

                </InputLabel>

                <OutlinedField
                    fullWidth
                    autoComplete="off"
                    margin="none"
                    type="text"
                    name="date-outlined"
                    InputProps={{formControl: classNames(classes.inputRoot), classes:{input: classNames(classes.input)}}}
                    value={value || (range.input !== undefined ? range.input : formatDateRange(range, dateFormat) )}
                    onChange={this.handleInputChange}
                    error={error}
                    onClick={this.onStartEdit}
                     />

                    <CalendarIcon className={classNames(classes.svg)} onClick={this.fastEditHandler} />
                    
                    <DateRangePicker
                        mode="list"
                        ref={(dateRangePicker) => this.dateRangePicker = dateRangePicker}
                        className="date-range-picker"
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        dateFormat={dateFormat}
                        onChange={this.handleRangeChange.bind(this)}
                        ranges={[isValid(range.startDate) && isValid(range.endDate) ? range : {startDate: new Date(), endDate: new Date()}]}
                        onClose={this.fastEditHandler}
                        minDate={minDate} />
                        
            </FormControl>
        );
    }
}

DoubleDateOutlinedField.defaultProps = {
    dateFormat: "dd/mm/yyyy"
}
DoubleDateOutlinedField.propTypes = {
    range: PropTypes.object.isRequired, /* {selection: { startDate: new Date(), endDate: new Date() } input: "" } */
    onRangeChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onChange: PropTypes.func,
}

export default withStyles(styles)(DoubleDateOutlinedField);