import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Calendar from './Calendar.js';
import { format } from 'date-fns';
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
//import calendarIcon from '../../../../general/icons/date_range.svg';
import CalendarIcon from '@material-ui/icons/DateRange';
import locale from 'date-fns/locale/en-US';
import classnames from 'classnames';
import '../styles.css';
import '../theme/default.css';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { InputLabel, FormControl } from '@material-ui/core'; 

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
        open: false
    };

    this.input = React.createRef();

    let regExp = new RegExp("([a-zA-Z])");
    this.props.dateFormat.split("").map(char => {
      if(regExp.exec(char) === null) {
        this.delimeter = char;
      }
      return null;
    });

    this.handleToggle = this.handleToggle.bind(this);
    this.handleInputOnKeyPress = this.handleInputOnKeyPress.bind(this);

    this.formatArray = this.props.dateFormat.split(this.delimeter).map(el => el.toLowerCase());
  }

  handleOnChange(event) {
    if(event.target.value.indexOf("_") == -1) {
      if(event.target.value != "") {
        const dateParts = event.target.value.split(this.delimeter);
        const date = new Date(dateParts[this.formatArray.indexOf('yyyy')], dateParts[this.formatArray.indexOf('mm')]-1, dateParts[this.formatArray.indexOf('dd')]);

        this.props.onInputChange("start", date);

        if(this.state.open) {
          this.calendar.changeShownDate(date);

          if(this.props.closeOnComplete)
            this.handleBlur();
        }
      } else {
        this.props.onInputChange("start", "");
      }
    }
  }

  handleInputOnKeyPress(event) {
    if(event.key === "Enter") {
      this.props.inputOnEnter(event);
      this.handleOnChange(event);

      this.setState({ open: false });
    }

    this.props.inputOnKeyPress(event);
  }

  handleToggle() {
    this.setState({open: !this.state.open});
  }

  handleFocus() {
    this.setState({open: true});
  }

  handleBlur() {
    this.setState({open: false});
  }

  handleChange(date) {
    this.props.onChange(date);

    if(this.props.closeCalendarOnComplete || this.props.closeOnComplete)
      this.handleBlur();
  }

  focusOnInput() {
    this.input.current.inputElement.focus();
  }

  render() {
    // const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe(this.props.dateFormat.toLowerCase());

    const regExp = new RegExp("([a-zA-Z])");
    const mask   = this.props.dateFormat.split("").map(char => {
      if(regExp.exec(char) === null)
        return char;

      return /\d/;
    });

    const { disabled } = this.props;

    const date = (this.props.date == "" || this.props.date == "Invalid Date") ? new Date() : this.props.date;

    return (
        <ClickAwayListener onClickAway={() => this.handleBlur()}>
        <div className={classnames("dateWrapper", this.props.className, disabled && "disabled")}>
            <div>
                {this.props.mode != "list" ? (<FormControl className={"formControl"} variant="filled">
                  <InputLabel
                    ref={ref => {
                      this.labelRef = ReactDOM.findDOMNode(ref);
                    }}
                    shrink={true}>
                    {this.props.label}
                  </InputLabel>
                  <div className="inputWrapper">
                    <MaskedInput
                        disabled={this.props.disabled}
                        mask={mask}
                        ref={this.input}
                        className="datePickerInput"
                        keepCharPositions={true}
                        guide={true}
                        pipe={autoCorrectedDatePipe}
                        value={date == "0000-00-00" ? null : format(this.props.date, this.props.dateFormat.toUpperCase())}
                        onChange={(e) => this.handleOnChange(e)}
                        onFocus={(e) => this.handleFocus(e)}
                        onKeyPress={(e) => this.handleInputOnKeyPress(e)} />
                    <CalendarIcon
                        alt="Date picker"
                        onClick={(e) => this.handleToggle(e)}
                    />
                  </div>
                </FormControl>
                ) : (
                  <div className="inputWrapper">
                    <MaskedInput
                        disabled={this.props.disabled}
                        mask={mask}
                        ref={this.input}
                        className="datePickerInput"
                        keepCharPositions={true}
                        guide={true}
                        pipe={autoCorrectedDatePipe}
                        value={date == "0000-00-00" ? null : format(this.props.date, this.props.dateFormat.toUpperCase())}
                        onChange={(e) => this.handleOnChange(e)}
                        onFocus={(e) => this.handleFocus(e)}
                        onKeyPress={(e) => this.handleInputOnKeyPress(e)} />
                    <CalendarIcon
                        alt="Date picker"
                        // onClick={(e) => this.handleToggle(e)}
                    />
                  </div>
                )}
            </div>
            {this.state.open && !this.props.disabled &&
                <div className="calendarView">
                    <Calendar
                        date={date == "0000-00-00" ? new Date : date}
                        onChange={(date) => this.handleChange(date)}
                        locale={locale}
                        ref={t => (this.calendar = t)}
                        showDateDisplay={false}
                    />
                </div>
            }
        </div>
        </ClickAwayListener>
    );
  }
}

DatePicker.defaultProps = {
  dateFormat: "yyyy-mm-dd",
  mode: "input",
  disabled: false,
  onChange: () => {},
  onInputChange: () => {},
  inputOnEnter: () => {},
  inputOnKeyPress: () => {},
  closeOnComplete: true,
  closeCalendarOnComplete: true
};

export default DatePicker;
