import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import Utils from "../general/Utils";

// TODO: 
// -Customizable identifier key.

// Utilities mainly designed for use in List, but which may also be useful elsewhere.

// Find an object defined by the rule in f (f.ex. (element) => element.id === "13"); a is an array containing the data;
// childrenPropertyName defines the name of the property that has the object's children.
function findRecursively(a, f, childrenPropertyName = "children") {
    let found = undefined;

    for(let i = 0; i < a.length && found === undefined; i++) {
        found = f(a[i]) ? a[i] : findRecursively(a[i][childrenPropertyName], f, childrenPropertyName);
    }

    return found;
}


// Differs with gatherAncestors quite a bit,
// since the row itself instead of id and data is passed to the 
// function. A more unified "parameter interface" for these functions
// would be good.
function gatherIdsRecursively(row, childrenPropertyName = "children") {
    let gathered = [row.data.id];

    if(row.hasOwnProperty(childrenPropertyName) && Array.isArray(row[childrenPropertyName]) && row[childrenPropertyName].length > 0) {
        for(let x in row.children) {
            let y = row.children[x];

            let fromChild = gatherIdsRecursively(y, childrenPropertyName);

            for(let c in fromChild) {
                gathered.push(fromChild[c]);
            }
        }
    }

    return gathered;
}


function _validParentId(id) {
    for(let v of [undefined, null,  0, "0"]) {
        if(id === v)
            return false;
    }

    return true;
}


// TODO: What if there's nothing in data, etc.? Throw errors or something smart.
// Returns the ids of the ancestors of the passed id, with the passed id included.
function gatherAncestors(id, data, parentKey = "parentId", idType = "number") {
    let cast = p => p;

    if(idType === "number") {
        cast = p => Number(p);
    } else if(idType === "string") {
        cast = p => String(p);
    }

    const nId       = cast(id);
    const map       = makeMap(data, "id");
    const ancestors = [id];
    const row       = data.find(d => cast(d.id) === nId);

    if(!row)
        return [];

    let parentId = map[id][parentKey];

    while(parentId) {
        if(!_validParentId(parentId))
            break;

        ancestors.push(parentId);

        if(!map.hasOwnProperty(parentId))
            break;

        parentId = map[parentId][parentKey];
    }

    return ancestors;
}


function gatherTree(id, data, dataTreeFormatted = false, parentKey = "parentId", idType = "number") {
    let cast = p => p;

    if(idType === "number") {
        cast = p => Number(p);
    } else if(idType === "string") {
        cast = p => String(p);
    }

    const nId  = cast(id);
    const tree = !dataTreeFormatted ? treeFormatDataForList(data, parentKey, undefined, idType) : dataTreeFormatted;
    let row    = data.find(d => cast(d.id) === nId);

    if(!row)
        return [];

    let parent;

    // Find the top-most parent from which we can then ascend to all children.
    if(row[parentKey]) {
        while(true) {
            row[parentKey] = cast(row[parentKey]);
            parent         = undefined;

            for(let x in data) {
                let y = data[x];

                if(cast(y.id) !== row[parentKey])
                    continue;

                parent = y;

                break;
            }

            if(parent)
                row = parent;
            else
                break;
        }
    }

    // Find the top parent from the tree structure, 
    // so that it will have references to its children.
    const rowId     = cast(row.id);
    const topParent = tree.find(d => cast(d.data.id) === rowId);
    const treeIds   = gatherIdsRecursively(topParent, "children");

    return treeIds;
}


// Works for objects. For primitives, check makeMapOfPrimitives.
function makeMap(arr, key = "id") {
    const map = {};

    for(let e of arr)
        map[e[key]] = e;

    return map;
}


function makeMapOfPrimitives(arr, bool = true) {
    const map = {};

    for(let e of arr)
        map[e] = bool;

    return map;
}


function createTreeOptions(options, level = 0, parent = undefined, idKey = "id") {
    let finalOptions = [];
    for(let i = 0; i < options.length; ++i) {
        let tempOpt = options[i];
        let opt     = {
            ...tempOpt.data,
            id: tempOpt.data[idKey],
            value: tempOpt.data[idKey],
            name: tempOpt.data.name,
            label: tempOpt.data.label,
            data: tempOpt.data,
            children: tempOpt.children,
            parentProps: { 
                data: parent !== undefined ? parent.data : {}, 
                children: parent !== undefined ? parent.children : [], 
                parentProps: parent !== undefined ? parent.parentProps : {} 
            },
            level: level,
            isChild: level > 0,
            isLastChild: level > 0 && i === options.length - 1
        };

        finalOptions.push(opt);

        if(!tempOpt.children || !tempOpt.children[0])
            continue;

        finalOptions = [...finalOptions, ...createTreeOptions(tempOpt.children, level + 1, opt, idKey)];
    }

    return finalOptions;
}


function flattenTreeFormattedData(data = false) {
    const flattened = [];

    if(!data) {
        return flattened;
    }

    for(let row of data) {
        flattened.push(row);

        if(!row.hasOwnProperty("children") || !Array.isArray(row.children) || row.children.length === 0) {
            continue;
        }

        for(let childRow of flattenTreeFormattedData(row.children)) {
            flattened.push(childRow);
        }
    }

    return flattened;
}


// TODO: Refactor; too many parameters, bad order, etc.
function treeFormatDataForList(data, parentKey = "parentId", order = undefined, idType = "number", idKey = "id") {
    let cast = p => p;

    if(idType === "number") {
        cast = p => Number(p);
    } else if(idType === "string") {
        cast = p => String(p);
    }

    data = cloneDeep(data);
    data = (Array.isArray(data)) ? data : [data];

    if(data.length === 0) {
        return [];
    }

    const originalOrder        = order === undefined ? data.map(el => el[idKey]) : order;
    const parentParentIdValues = [undefined, null,  0, "0"];
    let allIdsMap              = {};
    let map                    = {};
    let finalData              = [];
    let finalMap               = {};
    let isParentMap            = {};

    data.map(el => {
        el[idKey] = cast(el[idKey]);

        return el;
    }).forEach(el => {
        let tempObject 			= {};

        tempObject.data 		= Object.assign({}, el);
        tempObject.children 	= [];
        map[tempObject.data[idKey]] = tempObject;

        allIdsMap[el[idKey]] = true;
    });

    // Move all children under their parents. 
    for(let i of originalOrder) {
        if(!map[i] || !map[i].data) {
            continue;
        }

        if(!map[i].data.hasOwnProperty(parentKey) || !allIdsMap[map[i].data[parentKey]] || parentParentIdValues.indexOf(map[i].data[parentKey]) > -1) {
            isParentMap[map[i].data[idKey]] = true;
            continue;
        }

        map[map[i].data[parentKey]].children.push(map[i]);

        isParentMap[map[i].data[idKey]] = false;
    }

    // Form an array where there are no children on the first level, 
    // and children only occur under their parent's children property.
    for(let i in map) {
        // If the row is a child and its parent is in our set, do not include it in the final data, 
        // because it's already included in one of the parents in the children property.
        if(!isParentMap[map[i].data[idKey]] && parentParentIdValues.indexOf(map[i].data[parentKey]) === -1 && allIdsMap[map[i].data[parentKey]]) {
            continue;
        }

        finalMap[map[i].data[idKey]] = map[i];
    }
    
    // Since numerical keys in a JavaScript object are added in order, 
    // the original order of the data passed to this function has changed.
    // Therefore we need to preserve the data by picking things from the map in the original order.
    for(let id of originalOrder) {
        // Not all elements are "on the main level" anymore; some are in their parent's 'children' property.
        if(!isParentMap[id]) {
            continue;
        }

        finalData.push(finalMap[id]);
    }

    return finalData;
}


// TODO: treeFormatDataForList should also take this rowKey parameter, 
// and use the field determined by the parameter instead of the id field by default.
function formatDataForList(data, rowKey = "id") {
    data = (Array.isArray(data)) ? data : [data];

    if(data.length === 0) {
        return [];
    }

    const finalData = [];
    const tempData  = [];
    const idOrder   = [];

    for(let i in data) {
        let tempObject = {};

        tempObject.data = Object.assign({}, data[i]);
        tempObject.children = [];

        tempData.push(tempObject);

        idOrder.push(data[i][rowKey]);
    }

    const map = {};

    for(let el of tempData) {
        map[el.data[rowKey]] = el;
    }

    for(let id of idOrder) {
        finalData.push(map[id]);
    }

    return finalData;
}


function findMaxDepth(map, parentKey = "parentId") {
    let maxDepth = 0;
    let depth    = 0;

    for(let x in map) {
        let y = map[x];

        if(!y[parentKey])
            continue;

        depth = _findMaxDepth(map, y[parentKey], parentKey);

        if(depth > maxDepth)
            maxDepth = depth;
    }

    return maxDepth;
}


function _findMaxDepth(map, parentId, parentKey, level = 0) {
    return !map[parentId] || !map[parentId][parentKey] ? level : _findMaxDepth(map, map[parentId][parentKey], parentKey, ++level);
}


function debug__getObjectDiffs(a, b) {
    const diff = [];

    Object.keys(a).forEach(key => {
        if(!b.hasOwnProperty(key)) {
            diff.push(key);

            return;
        }

        if(!isEqual(a[key], b[key])) {
            diff.push(key);

            return;
        }
    });

    return diff;
}


export {
    makeMap,
    makeMapOfPrimitives,
    createTreeOptions,
    findRecursively,
    gatherIdsRecursively,
    gatherAncestors,
    gatherTree,
    treeFormatDataForList,
    flattenTreeFormattedData,
    formatDataForList,
    findMaxDepth,

    debug__getObjectDiffs,
};

